import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Fade, List, Paper, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { hideModal } from "../../redux/reducers/rootReducer";
import Uploader from "../Upload/Upload";
import UploaderVideo from "../Upload/UploadVideos";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import { getModelMedia } from "../../redux/actions/modelAction";
import { isMobile } from "react-device-detect";
import CloseButton from "../../UI/CloseButton";

export default function MyMediaModal({ modal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const session = useSelector((state) => state.session);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (session && session.user && session.user.id)
      dispatch(getModelMedia(session.user.id));
  };

  const stylePass = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: isMobile ? "100%" : "50%",
    minWidth: 320,
    height: isMobile ? "80vh" : "fit-content",
    minHeight: isMobile ? "40vh" : "25vh",
    maxHeight: isMobile ? "100vh" : "80vh",
    bgcolor: "#171717",
    width: "100%",
    pt: isMobile ? 6 : 4,
    px: isMobile ? 2 : 4,
    pb: isMobile ? 2 : 4,
    color: "#FFFFFF",
    borderRadius: "10px",
  };

  const handleClose = () => {
    // setSearchParams({login: false});
    dispatch(hideModal("modelMedia"));
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={modal && modal.name === "modelMedia"}
      onClose={handleClose}
    >
      <Fade in>
        <Box sx={stylePass}>
          <CloseButton onClick={handleClose} />
          <Box sx={{ width: "100%" }}>
            <Fade in delay={200} timeout={400}>
              <Box
                sx={{ width: "100%", typography: "body1", color: "#FFFFFF" }}
              >
                <TabContext value={value}>
                  <TabList onChange={handleChange} aria-label="Media">
                    <Tab
                      icon={<PhotoCameraBackIcon />}
                      iconPosition="start"
                      className={"tab-primary " + (value === 1 ? "active" : "")}
                      label="PHOTOS"
                      value="1"
                    />
                    <Tab
                      icon={<OndemandVideoIcon />}
                      iconPosition="start"
                      className={"tab-primary " + (value === 2 ? "active" : "")}
                      label="VIDEOS"
                      value="2"
                    />
                  </TabList>
                  <TabPanel value="1" style={{ padding: 0 }}>
                    <Paper style={{ width: "95%", minHeight: "100px" }}>
                      <List
                        className="AllRoomBlock_list_images"
                        style={{ padding: "8px" }}
                      >
                        <Uploader id={session?.user?.id} />
                      </List>
                    </Paper>
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: 0 }}>
                    <Paper style={{ width: "95%", minHeight: "100px" }}>
                      <List
                        className="AllRoomBlock_list_images"
                        style={{ padding: "8px" }}
                      >
                        <UploaderVideo id={session?.user?.id} />
                      </List>
                    </Paper>
                  </TabPanel>
                </TabContext>
              </Box>
            </Fade>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
