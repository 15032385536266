import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Fade, List, ListItem } from "@mui/material";
import { hideModal } from "../../redux/reducers/rootReducer";
import { isMobile } from "react-device-detect";
import CloseButton from "../../UI/CloseButton";
import Pool from "../ChatBlock/PoolTab/Pool";

export default function PoolsModal({ modal }) {
  const dispatch = useDispatch();
  const pools = useSelector((state) => state.pools);

  const stylePass = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: isMobile ? "100%" : "30%",
    minWidth: 320,
    height: isMobile ? "80vh" : "fit-content",
    minHeight: isMobile ? "" : "65vh",
    maxHeight: isMobile ? "" : "100vh",
    bgcolor: "#171717",
    width: "100%",
    p: 1,
    color: "#FFFFFF",
    borderRadius: "10px",
  };

  const handleClose = () => {
    // setSearchParams({login: false});
    dispatch(hideModal("pools"));
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={modal?.name === "pools"}
      onClose={handleClose}
    >
      <Fade in>
        <Box sx={stylePass}>
          <CloseButton onClick={handleClose} sx={{ zIndex: 2 }} />
          <Box sx={{ width: "100%" }}>
            <Fade in delay={200} timeout={400}>
              <Box
                sx={{ width: "100%", typography: "body1", color: "#FFFFFF" }}
              >
                <List
                  sx={{
                    width: "100%",
                    height: window.innerHeight / 1.5 + "px",
                    overflowY: "scroll",
                  }}
                >
                  {pools?.length > 0 &&
                    pools.map((pool, index) => {
                      return (
                        <ListItem sx={{ width: "100%" }} key={"pol" + index}>
                          <Pool pool={pool} />
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </Fade>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
