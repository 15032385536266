import { REMOVE_IS_IN_PRIVATE, SET_IS_IN_PRIVATE } from '../types'

const initialState = {
  status: false,
  liveshowId: null,
  url: null,
  prevUrl: null
};
export const isInPrivateReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case SET_IS_IN_PRIVATE: {
      const { data } = payload
      return {
        status: true,
        liveshowId: data.liveshowId,
        url: data.url,
        prevUrl: data.prevUrl,
      }
    }
    case REMOVE_IS_IN_PRIVATE: {
      return {
        status: false,
        liveshowId: null,
        url: null,
        prevUrl: null
      }
    }
    default: {
      return state
    }
  }
}
