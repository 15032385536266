import { EDIT_GALLERY, MODEL_GALLERY } from '../types'
const initialState = {};
export const galleryReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case MODEL_GALLERY: {
      const { data } = payload
      return data;
    }
    case EDIT_GALLERY: {
      const { data } = payload
      const newState = Object.assign({},state);
      const obj =  newState.media.find((item) => item.id === data.id);
      obj.isPurchased = 1
      obj.fullMovie = data.fullMovie
      const idx = newState.media.indexOf(obj);
      newState.media[idx] = obj
      return newState;
    }
    

    default: {
      return state;
    }
  }
}
