import { CAMERA, REMOVE_CAMERA, UPDATE } from '../types'

export const cameraReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case CAMERA: {
      const { data } = payload

      if(data === state)
        return state;

      return data
    }
    case UPDATE: {
      const { data } = payload
      return data
    }

    case REMOVE_CAMERA: {
      return {}
    }
    
    default: {
      return state
    }
  }
}
