import { MODEL, MODEL_DECREMENT_KISSES, MODEL_INCREMENT_KISSES, MODEL_SET_KISSED, MODEL_UNSET_KISSED, UPDATE } from '../types'

export const modelReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case MODEL: {
      const { data } = payload
      return data;
    }

    case MODEL_INCREMENT_KISSES: {
      let newState = Object.assign({},state);
      newState.kisses += 1 
      return newState;
    }

    case MODEL_DECREMENT_KISSES: {
      let newState = Object.assign({},state);
      newState.kisses -= 1 
      return newState;
    }

    case MODEL_SET_KISSED: {
      let newState = Object.assign({},state);
      newState.favorite = true 
      return newState;
    }

    case MODEL_UNSET_KISSED: {
      let newState = Object.assign({},state);
      newState.favorite = false
      return newState;
    }
    

    case UPDATE: {
      const { data } = payload
      return data
    }
    
    default: {
      return state
    }
  }
}
