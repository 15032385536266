import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useState } from "react";
import { deleteMedia, getModelMedia } from "../../redux/actions/modelAction";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
const getBase64 = (file) =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});
const mapStateToProps = (state) => {
  return {
    model_media: state.media,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getModelMedia: (id) => dispatch(getModelMedia(id)),
  };
};

const Uploader = ({ model_media, getModelMedia, id }) => {
  
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    setFileList([])
    getModelMedia(id);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let newFileList = [];
    if (model_media && model_media.images && model_media.images.length === 0) {
      setFileList([]);
    }
    model_media &&
      model_media.images &&
      model_media.images.forEach((image) => {
        newFileList.push({
          uid: image.id,
          status: "done",
          url: image.path,
          response: '{"status": "success"}', // response from server
          linkProps: '{"download": "image"}',
        });
        setFileList(newFileList);
      });
  }, [model_media]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleRemove = (info) => {
    // eslint-disable-next-line no-unused-expressions
    handleChange;
    dispatch(deleteMedia(info.uid));
  };
  const handleValidate = (info) => {
    const isPNG = info.type.includes("image");
    if (!isPNG) {
      alert(`file must be type of image`);
    }
    return isPNG || Upload.LIST_IGNORE;
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ color: "white" }} />
      <div
        style={{
          marginTop: 8,
          color: "white",
        }}
      >
        {t("Upload")}
      </div>
    </div>
  );

  const props = {
    action: process.env.REACT_APP_BACKEND_URL + `/api/v1/models/media/upload-items/` + (id ? id : ""),
    method: "POST",
    headers: { Authorization: `Bearer ` + localStorage.getItem("access_token") },
    listType: "picture-card",
    name: "myFiles",
  };

  return (
    <>
      <Upload
        {...props}
        fileList={fileList}
        data={{ mediaType: "image" }}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        beforeUpload={handleValidate}
        defaultFileList={setFileList}
        key={setFileList}
      /* style={{backgroundColor:'black',color:'white'}} */
      >
        {fileList.length >= 30 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="preview"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploader);
