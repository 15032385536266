import { LIVESHOW, LIVESHOW_DELETE, LIVESHOW_NO_HLS } from '../types'

export const liveshowReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case LIVESHOW: {
      const { data } = payload
      return data
    }

    case LIVESHOW_DELETE: {
      return {}
    }
    
    case LIVESHOW_NO_HLS: {
      let newState = Object.assign({},state);
      newState.hls_url = null;
      return newState;
    }
    default: {
      return state
    }
  }
}
