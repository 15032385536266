import { MODEL_EARNINGS } from "../types";

export const modelEarningsReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {

    case MODEL_EARNINGS: {
      const { data } = payload;
      return data;
    }

    default: {
      return state;
    }
  }
};
