import {
  API_LOGIN_ROUTE,
  API_MEMBER_PROFILE_EDIT_ROUTE,
  API_REGISTRATION_ROUTE,
  API_USER_FINDME_ROUTE,
  API_USER_DELETE_AVATAR,
  API_USER_CHANGE_PASSWORD_ROUTE,
  API_USER_PROFILE_FETCH_ROUTE,
  API_USER_SENDS_KISS_ROUTE,
  API_USER_SENDS_GIFT_ROUTE,
  API_FETCH_PAYMENT_PACKAGES_ROUTE,
  API_USER_SENDS_MESSAGE_ROUTE,
  API_FETCH_USER_CONVERSATIONS,
  API_FETCH_USER_NOTIFICATIONS,
  API_USER_MODEL_UPDATE_ROUTE,
  API_MODEL_PROFILE_EDIT_ROUTE,
  API_USER_SET_AVATAR,
  API_USER_AVATAR_UPDATE,
  API_UPDATE_BINOM,
} from "../../utils/consts";
import {
  hideModal,
  removeError,
  setError,
  setErrors,
  setMessage,
  setPurchaseType,
  setTab,
} from "../reducers/rootReducer";
import {
  USER,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  USER_AVATAR_DELETE,
  USER_UPDATE,
  USER_PROFILE_SET,
  MODEL_DECREMENT_KISSES,
  MODEL_INCREMENT_KISSES,
  MODEL_SET_KISSED,
  MODEL_UNSET_KISSED,
  USER_BALANCE,
  CONVERSATIONS,
  BINOM,
  BINOM_REMOVE,
  FETCH_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  ADD_NOTIFICATION,
  CONVERSATIONS_REDUCE_UNREAD,
  CONVERSATIONS_INCREASE_UNREAD,
  ONLINE,
  OFFLINE,
  AUTH_ENV_USER,
} from "../types";
import { headers } from "../config";
import axios from "axios";
import { setBalance } from "./environmentAction";

let token;

if (typeof window !== "undefined") {
  token = localStorage.getItem("access_token");
}

export const userSignin = (data) => {
  return {
    type: AUTH_LOGIN,
    payload: { data },
  };
};
export const userSigninEnv = (data) => {
  return {
    type: AUTH_ENV_USER,
    payload: { data },
  };
};

const userAvatarDelete = () => {
  return {
    type: USER_AVATAR_DELETE,
  };
};

const userLogout = (data) => {
  return {
    type: AUTH_LOGOUT,
    payload: { data },
  };
};

const setUserProfile = (data) => {
  return {
    type: USER_PROFILE_SET,
    payload: { data },
  };
};

const oneUser = (data) => {
  return {
    type: USER,
    payload: { data },
  };
};

const patchUser = (data) => {
  return {
    type: USER_UPDATE,
    payload: { data },
  };
};

const incrementKisses = () => {
  return {
    type: MODEL_INCREMENT_KISSES,
  };
};

const setKissed = () => {
  return {
    type: MODEL_SET_KISSED,
  };
};

const unsetKissed = () => {
  return {
    type: MODEL_UNSET_KISSED,
  };
};

export const setUserBalance = (data) => {
  return {
    type: USER_BALANCE,
    payload: { data },
  };
};

const decrementKisses = () => {
  return {
    type: MODEL_DECREMENT_KISSES,
  };
};

export const setConversations = (data) => {
  return {
    type: CONVERSATIONS,
    payload: { data },
  };
};

export const setNotifications = (data) => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: { data },
  };
};

export const addNotifications = (data) => {
  return {
    type: ADD_NOTIFICATIONS,
    payload: { data },
  };
};

export const addNotification = (data) => {
  return {
    type: ADD_NOTIFICATION,
    payload: { data },
  };
};

export const setBinom = (data) => {
  return {
    type: BINOM,
    payload: { data },
  };
};

export const increaseUnreadConversations = (data) => {
  return {
    type: CONVERSATIONS_INCREASE_UNREAD,
    payload: { data },
  };
};

export const reduceUnreadConversations = (data) => {
  return {
    type: CONVERSATIONS_REDUCE_UNREAD,
    payload: { data },
  };
};

export const removeBinom = (data) => {
  return {
    type: BINOM_REMOVE,
    payload: { data },
  };
};

export const setOnline = () => {
  return {
    type: ONLINE,
  };
};

export const setOffline = () => {
  return {
    type: OFFLINE,
  };
};

export const getConversations = () => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_USER_CONVERSATIONS,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.status === 200) {
      dispatch(setConversations(res.data.data.conversations));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getNotifications =
  (skipe = null) =>
  async (dispatch) => {

    try {
      const res = await axios.get(
        process.env.REACT_APP_BACKEND_URL + API_FETCH_USER_NOTIFICATIONS,

        skipe
          ? {
              withCredentials: true,
              params: {
                skip: skipe,
              },
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ` + localStorage.getItem("access_token"),
              },
            }
          : {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ` + localStorage.getItem("access_token"),
              },
            }
      );
      if (res.status === 200) {
        if (skipe) {
          dispatch(addNotifications(res.data.data));
        } else {
          dispatch(setNotifications(res.data.data));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

export const markNotifications = (ids) => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_USER_NOTIFICATIONS,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
        body: JSON.stringify({ notifications: ids }),
        credentials: "include",
      }
    );
    // if(response.status ===200){
    //   dispatch(getNotifications())
    // }
  } catch (e) {
    console.error(e);
  }
};

export const setNewPasswordAction = (data) => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_CHANGE_PASSWORD_ROUTE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
        body: JSON.stringify(data),
        credentials: "include",
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      dispatch(setMessage(data.message, data.type));
      dispatch(hideModal("password"));
    } else {
      const data = await response.json();
      dispatch(setErrors(data.errors));
    }
  } catch (error) {
    dispatch(setErrors(error));
    return false;
  }
};

export const getUser = () => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_FINDME_ROUTE,
      {
        method: "GET",
        headers: headers,
      }
    );

    const data = await response.json();
    dispatch(oneUser(data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const getUserProfile = (id) => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_PROFILE_FETCH_ROUTE + id,
      {
        method: "GET",
        headers: localStorage.getItem("access_token")
          ? {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ` + localStorage.getItem("access_token"),
            }
          : {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        credentials: "include",
      }
    );

    const data = await response.json();
    if (data) {
      dispatch(setUserProfile(data));
    }
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateUserProfile = (items) => async (dispatch) => {
  try {
    let formData = new FormData();

    Object.keys(items).forEach((key) => formData.append(key, items[key]));

    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_MODEL_UPDATE_ROUTE,
      formData,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
          accept: "application/json",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      }
    );

    if (res.status === 200) {
      dispatch(patchUser(res.data));
      dispatch(setUserProfile(res.data));
      dispatch(setMessage(res.data.message));
    }
  } catch (error) {
    console.error(error);
  }
};

export const loginUser = (user) => async (dispatch) => {
  localStorage.removeItem("persist:root");
  localStorage.removeItem("core_api_token");
  localStorage.removeItem("access_token");
  window.localStorage.clear();
  window.localStorage.clear();
  localStorage.clear();
  localStorage.clear();
  window.sessionStorage.clear();
  window.localStorage.clear();
  dispatch(setBalance({}));
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(user),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGIN_ROUTE,
      options
    );
    const data = await response.json();

    if (data.access_token) {
      dispatch(userSignin(data));
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("core_api_token", data.kewb_token);
    } else {
      dispatch(setError(data.message));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const sendMessagePrivate = (message, modelId) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      body: JSON.stringify({ message: message }),
    };
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        API_USER_SENDS_MESSAGE_ROUTE +
        modelId +
        "/send-message",
      options
    );
    const data = await response.json();
    if (response.status === 400) {
      dispatch(setMessage("Not enough tokens on your balance!", "error"));
    }
    if (response.status == 201) {
      dispatch(setBalance(data.balance));
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendMessage = (message, modelId) => async (dispatch) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        API_USER_SENDS_MESSAGE_ROUTE +
        modelId +
        "/send-message",
      { message: message },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.status === 200) {
      dispatch(setMessage(res.data.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendKiss = (id) => async (dispatch) => {
  try {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      body: null,
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        API_USER_SENDS_KISS_ROUTE +
        id +
        "/like",
      options
    );
    const res = await response.json();
    if (res.success) {
      if (res.data.like.status === "unlike") {
        dispatch(decrementKisses());
        dispatch(unsetKissed());
      } else if (res.data.like.status === "like") {
        dispatch(incrementKisses());
        dispatch(setKissed());
      }
    }

    if (res.error === "Unauthenticated.") {
      dispatch(setMessage("You are not authenticated.", "error"));
      localStorage.removeItem("persist:root");
      window.location.href = "/register";
    }
  } catch (error) {
    dispatch(setError(error));
  }
};

export const sendGift = (id, gift) => async (dispatch) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_SENDS_GIFT_ROUTE + id,
      gift,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.status === 200) {
      dispatch(removeError());
    } else {
      res.data.message
        ? dispatch(setMessage(res.data.message, "error"))
        : res.data.errors && dispatch(setErrors(res.data.errors));
    }
  } catch (error) {
    dispatch(setError(error));
  }
};

export const buyPackage =
  (type, packageId, custom_amount, crypto) => async (dispatch) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + API_FETCH_PAYMENT_PACKAGES_ROUTE,
        {
          payment_method: type,
          packageId: packageId,
          custom_amount: packageId === 0 ? custom_amount : null,
          crypto_currency: crypto,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
      if (res) {
        if (type === "crypto") window.open(res.data.data.url, "_self");
        else window.location.href = res.data.data.url;
      }
    } catch (error) {
      setMessage(error, "error");
    }
  };

export const redirectToCrypto = (amount) => async (dispatch) => {
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        "/api/v1/buy-tokens/" +
        amount +
        "/crypto",
      options
    );
    const data = await response.json();

    if (data.type === "success") {
      window.location.href = data.data;
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const deleteAvatar = (avatar) => async (dispatch) => {
  try {
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      credentials: "include",
      body: JSON.stringify({
        avatar: avatar,
      }),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_DELETE_AVATAR,
      options
    );
    const data = await response.json();
    if (data.response_code === 200) {
      dispatch(userAvatarDelete(data));
    } else {
      dispatch(setError(data));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const setAvatar = (avatar) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      credentials: "include",
      body: JSON.stringify({
        avatar: avatar,
      }),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_SET_AVATAR,
      options
    );
    const data = await response.json();
    if (data.success) {
      dispatch(patchUser(data.user));
      dispatch(setUserProfile(data.user));
    } else {
      dispatch(setError(data));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const uploadAvatar = (avatar, id) => async (dispatch) => {
  try {
    let formData = new FormData();
    Object.keys(avatar).forEach((key) => formData.append(key, avatar[key]));

    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_AVATAR_UPDATE + id,
      formData,
      {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (response.status === 200) {
      dispatch(patchUser(response.data.data.user));
      dispatch(setUserProfile(response.data.data.user));
      dispatch(setMessage(response.data.data.message, response.data.type));
    } else {
      dispatch(setError(response.data.message));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(userLogout());
  localStorage.removeItem("persist:root");
  localStorage.removeItem("core_api_token");
  localStorage.removeItem("access_token");
  window.localStorage.clear();
  window.localStorage.clear();
  localStorage.clear();
  localStorage.clear();
  window.sessionStorage.clear();
  window.localStorage.clear();
  dispatch(setBalance({}));
  localStorage.setItem("user_age_check", true);
  window.location.href = "/";
};

export const addNewUser =
  (item, binom = null) =>
  async (dispatch) => {
    try {
      if (
        binom &&
        binom !== {} &&
        Object.getOwnPropertyNames(binom).length !== 0
      ) {
        item["binom"] = binom;
        dispatch(removeBinom());
      }

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item),
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_REGISTRATION_ROUTE,
        options
      );
      const data = await response.json();

      if (data.access_token) {
        localStorage.setItem("core_api_token", data.kewb_token);
        localStorage.setItem("access_token", data.access_token);
        dispatch(userSignin(data));
      } else {
        dispatch(setErrors(data.errors));
      }
    } catch (error) {
      setMessage(error, "error");
    }
  };

export const updateUser = (item) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      credentials: "include",
      body: JSON.stringify(item),
    };
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_MEMBER_PROFILE_EDIT_ROUTE,
      options
    );

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      dispatch(
        patchUser({
          user: data.data.user,
          avatar: data.data.user.avatar,
          access_token: token,
        })
      );
      dispatch(setMessage(data.message, data.type));
    } else {
      const data = await response.json();
      dispatch(setMessage(Object.values(data.errors)[0], "error"));
    }
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateModelProfile = (item) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      credentials: "include",
      body: JSON.stringify(item),
    };
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_MODEL_PROFILE_EDIT_ROUTE,
      options
    );

    if (response.status === 200) {
      const data = await response.json();
      if (data) {
        dispatch(
          patchUser({
            user: data.user,
            avatar: data.user.avatar,
          })
        );
        dispatch(setMessage(data.message, data.type));
      }
    } else {
      const data = await response.json();
      dispatch(setMessage(Object.values(data.errors)[0], "error"));
    }
  } catch (error) {
    dispatch(setError(error));
  }
};

export const UpdateBinom = (item) => async (dispatch) => {

  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      credentials: "include",
      body: JSON.stringify(item),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_UPDATE_BINOM,
      options
    );
    if (response.status === 200) {
      dispatch(setTab("credits"));
      dispatch(setPurchaseType("credits"));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};
