/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAvatar } from '../../redux/actions/userAction';

export default function AvatarAvailable({avatar}) {

    const dispatch = useDispatch();

    function chooseAvatar(e) {
        e.preventDefault();
        dispatch(setAvatar(avatar));
    }

    return (
        <Button onClick={(e) => chooseAvatar(e)} className='setAvatarElem' style={{ margin: '5px 5px', width: '97%', textTransform: 'none', color: "#FFFFFF", justifyContent: 'space-evenly' }}>
            <Avatar
                alt="Avatar"
                src={avatar && avatar.image}
                sx={{ width: 90, height: 90, textAlign: 'center' }}
            />
            <Typography id="modal-modal-title" variant="h6" component="div" style={{ width: '180px' }}>
                {avatar && avatar.name}
            </Typography>
        </Button>
    );
}
