import { INCREMENT_POOL, POOL, POOLS } from '../types'

export const poolsReducer = (state = [], action) =>{

  const {type, payload} = action
  switch (type) {
    case POOLS: {
        const { data } = payload
        let newState = Object.assign([],state);
        newState = data;
        return newState
    }
    case POOL: {
        const { data } = payload
        return [...state, ...data];
    }
    case INCREMENT_POOL: {
      const { data } = payload

      let newState = Object.assign([],state);

      // eslint-disable-next-line array-callback-return
      newState.map((pool, i) => {
        if(pool.id === data.id){
          newState[i].credits_current += parseInt(data.amount);
        }
      });
      
      return newState
  }
    default: {
      return state
    }
  }
}
