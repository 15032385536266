import { MAGIC_TAB } from '../types'

export const magicTabReducer = (state = {}, action) => {
    const { type, payload } = action

    switch (type) {
        case MAGIC_TAB: {
            const { data } = payload
            return data
        }
        default: {
            return state
        }
    }
}
