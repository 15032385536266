import {
  API_CANCEL_REQUEST_PRIVATE_SHOW_ROUTE,
  API_FETCH_LIVESHOWS_ROUTE,
  API_FETCH_LIVESHOW_ROUTE,
  API_FETCH_USER_REQUESTS_ROUTE,
  API_REQUEST_PRIVATE_SHOW_ROUTE,
  API_USER_LEFT_LIVESHOW,
} from "../../utils/consts";
import {
  removeLoadings,
  setLoadings,
  setMessage,
} from "../reducers/rootReducer";
import {
  LIVESHOW,
  LIVESHOWS,
  LIVESHOW_DELETE,
  LIVESHOW_NO_HLS,
  ONE_USER_IN_CHAT,
  REMOVE_IS_IN_PRIVATE,
  REMOVE_PRIVATE_SHOW_REQUEST,
  REMOVE_PRIVATE_SHOW_REQUEST_BY_ID,
  REMOVE_USER_IN_CHAT,
  SET_IS_IN_PRIVATE,
  SET_PRIVATE_SHOW_REQUEST,
  SET_PRIVATE_SHOW_REQUESTS,
  SET_USER_IN_SHOW,
  USERS_IN_CHAT,
} from "../types";
import axios from "../../utils/axios";
import { getEnvironment, setPools } from "./environmentAction";
import { allOnline } from "./modelAction";

const allLiveshows = (data) => {
  return {
    type: LIVESHOWS,
    payload: { data },
  };
};

const oneLiveshow = (data) => {
  return {
    type: LIVESHOW,
    payload: { data },
  };
};

export const removeLiveshow = () => {
  return {
    type: LIVESHOW_DELETE,
  };
};

export const removeLiveshowHls = () => {
  return {
    type: LIVESHOW_NO_HLS,
  };
};

export const setUserInShow = (data) => {
  return {
    type: SET_USER_IN_SHOW,
    payload: { data },
  };
};

export const setUsersInChat = (data) => {
  return {
    type: USERS_IN_CHAT,
    payload: { data },
  };
};

export const removeUserInChat = (data) => {
  return {
    type: REMOVE_USER_IN_CHAT,
    payload: { data },
  };
};

export const setUserInChat = (data) => {
  return {
    type: ONE_USER_IN_CHAT,
    payload: { data },
  };
};

export const privateShowRequested = (data) => {
  return {
    type: SET_PRIVATE_SHOW_REQUEST,
    payload: { data },
  };
};
// const AllPrivateShowRequested = (data) => {
//   return {
//     type: SET_PRIVATE_SHOW_REQUESTS,
//     payload: { data },
//   };
// };

const setPrivateShowRequests = (data) => {
  return {
    type: SET_PRIVATE_SHOW_REQUESTS,
    payload: { data },
  };
};

export const setPrivateShowRequest = (data) => {
  return {
    type: SET_PRIVATE_SHOW_REQUEST,
    payload: { data },
  };
};

export const getLiveshow = (id) => async (dispatch) => {
  dispatch(setLoadings("liveshow"));
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        API_FETCH_LIVESHOW_ROUTE +
        id +
        "/initial",
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.data) {
      dispatch(oneLiveshow(res.data.data.liveshow));
      if (res.data.data.onlineUsers)
        dispatch(setUsersInChat(res.data.data.onlineUsers));
      if (res.data.data.pools && res.data.data.pools.length > 0)
        dispatch(setPools(res.data.data.pools));
      if (
        res.data.data.liveshow.performers &&
        res.data.data.liveshow.performers.length > 0
      ) {
        console.log('here')
        dispatch(allOnline(res.data.data.liveshow.performers));
      }
      if (res.data.data.liveshow && res.data.data.liveshow.type === "private") {
        dispatch(
          setIsInPrivate({
            liveshowId: res.data.data.liveshow.id,
            url: `/liveshows/${id}`,
            prevUrl: `/liveshows/${res.data.data.liveshow.parentLiveshowId}`,
          })
        );
      } else {
        dispatch(removeIsInPrivate());
      }
      dispatch(removeLoadings("liveshow"));
      dispatch(getEnvironment("liveshow"));
    } else {
      dispatch(removeLoadings("liveshow"));
    }
  } catch (error) {
    dispatch(removeLoadings("liveshow"));
    window.location.href = "/";
  }
};

export const userLeave = () => async (dispatch) => {
  try {
    await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_LEFT_LIVESHOW,
      {},
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
  } catch (error) {}
};

export const fetchPrivateShowRequests = () => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_USER_REQUESTS_ROUTE,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );

    if (res.status === 200) {
      if (res.data) {
        dispatch(setPrivateShowRequests(res.data.data));
      }
    }
  } catch (error) {}
};

export const requestPrivateShow = (id, param) => async (dispatch) => {
  let res = "";
  try {
    if (param === true) {
      res = await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          API_CANCEL_REQUEST_PRIVATE_SHOW_ROUTE +
          id +
          "/cancel",
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
    }
    if (param === false) {
      res = await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          API_REQUEST_PRIVATE_SHOW_ROUTE +
          id +
          "/request/private",
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
      if (res.data) {
        dispatch(
          privateShowRequested({
            publicLiveshowId: +id,
          })
        );

        dispatch(setMessage(res.data.message));
      }
    }
  } catch (error) {
    dispatch(setMessage(error.response.data.message, error.response.data.type));
  }
};

export const removePrivateShowRequest = (data) => {
  return {
    type: REMOVE_PRIVATE_SHOW_REQUEST,
    payload: { data },
  };
};

export const removePrivateShowRequestById = (data) => {
  return {
    type: REMOVE_PRIVATE_SHOW_REQUEST_BY_ID,
    payload: { data },
  };
};

export const setIsInPrivate = (data) => {
  return {
    type: SET_IS_IN_PRIVATE,
    payload: { data },
  };
};
export const removeIsInPrivate = () => {
  return {
    type: REMOVE_IS_IN_PRIVATE,
  };
};

export const getLiveshows = () => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_LIVESHOWS_ROUTE,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.data) {
      dispatch(allLiveshows(res.data));
    }
  } catch (error) {}
};
