import { ADD_NOTIFICATION, ADD_NOTIFICATIONS, FETCH_NOTIFICATIONS } from '../types'

const initialState = [];
export const notificationsReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case FETCH_NOTIFICATIONS: {
      const { data } = payload
      state = initialState;
      return data
    }
    case ADD_NOTIFICATIONS: {
      const { data } = payload
      let newState = Object.assign([],state);
      newState.notification_data.notifications = newState.notification_data.notifications.concat(data.notification_data.notifications);
      newState.notification_data.total = data.notification_data.total;
      newState.notification_data.unread = data.notification_data.unread;
      return newState;
    }
    case ADD_NOTIFICATION: {
      const { data } = payload
      let newState = Object.assign([],state);
      newState.notifications = newState.notifications.unshift(data);
      newState.total = data.total + 1;
      newState.unread = data.unread + 1;
      return newState;
    }
    default: {
      return state
    }
  }
}
