import { POST, POST_CLEAR } from '../types'

export const postReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {

    case POST: {
      let { data } = payload
      return data
    }
    case POST_CLEAR: {
      return [];
    }
    default: {
      return state
    }
  }
}
