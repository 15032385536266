import { USER_PROFILE_SET } from '../types'

export const profileReducer = (state = [], action) =>{

  const {type, payload} = action
  switch (type) {
    case USER_PROFILE_SET: {
        const { data } = payload
        return data
      }
    default: {
      return state
    }
  }
}
