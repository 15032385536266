import { ONE_USER_IN_CHAT, REMOVE_USER_IN_CHAT, USERS_IN_CHAT } from '../types'

export const usersInChatReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case USERS_IN_CHAT: {
      const { data } = payload
      let newState = Object.assign([],state);
      newState = data;
      return newState;
    }

    case ONE_USER_IN_CHAT: {
        const { data } = payload
        if(state.some(item => data.id === item.id))
          return state
        return [...state, data]
    }
    case REMOVE_USER_IN_CHAT: {
      const { data } = payload
      return state.filter(item => data.id !== item.id)
  }
    
    default: {
      return state
    }
  }
}
