// SettingsContext.js
import React, {
  createContext,
  useState
} from 'react';

const defaultSettings = {
  // you could add sort of global settings here
  theme: 'light'
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => { }
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);
  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;