import { ONLINES } from '../types'
const initialState = [];
export const onlinesReducer = (state = initialState, action) =>{
  const {type, payload} = action
  
  switch (type) {
    case ONLINES: {
      const { data } = payload
      state = initialState;
      return [...state, ...data]
    }
    default: {
      return state
    }
  }
}
