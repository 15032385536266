import { AUTH_ENV_USER, AUTH_LOGIN, AUTH_LOGOUT, USER_AVATAR_DELETE, USER_AVATAR_UPLOAD, USER_BALANCE, USER_EMAIL, USER_UPDATE } from '../types'

const initialState = {};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {

    case AUTH_LOGIN: {
      let { data } = payload
      return data
    }

    case AUTH_LOGOUT: {
      return {}
    }

    case USER_AVATAR_DELETE: {
      return {
        user: state.user,
        access_token: state.access_token,
        avatar: null,
        message: null
      }
    }


    case USER_AVATAR_UPLOAD: {
      let { data } = payload
      return {
        user: state.user,
        access_token: state.access_token,
        avatar: data.avatar
      }
    }

    case USER_BALANCE: {
      let { data } = payload
      const newState = Object.assign({}, state);
      if (!newState.user || !newState.user.credits)
        return state;
      newState.user.credits = data.amount;
      return newState;
    }

    case USER_UPDATE: {
      const { data } = payload
      const newState = Object.assign({}, state);
      newState.user.avatar = data.avatar;
      return newState;
    }

    case USER_EMAIL: {
      const { data } = payload
      const newState = Object.assign({}, state);
      if (newState.user?.email_verified) {
        newState.user.email_verified = data;
      }
      return newState;
    }
    case AUTH_ENV_USER: {
      const { data } = payload
      const newState = Object.assign({}, state);
      
      if (!newState.user)
        return newState;

      newState.user.id = data?.user?.id;
      newState.user.role = data?.user?.role;
      newState.user.avatar = data?.user?.avatar;
      newState.user.credits = data?.user?.credits;
      newState.user.username = data?.user?.username;
      newState.user.email = data?.user?.email;
      newState.user.email_verified = data?.user?.email_verified;
      // if (newState.user?.email_verified) {
      //   newState.user.email_verified = data;
      // }
      return newState;
    }

    default: {
      return state
    }
  }
}
