import { combineReducers } from "redux";
import { modelReducer } from "./modelReducer";
import { modelsReducer } from "./modelReducers";
import { userReducer } from "./userReducer";
import { camerasReducer } from "./cameraReducers";
import { cameraReducer } from "./cameraReducer";
import { liveshowsReducer } from "./liveshowReducers";
import { liveshowReducer } from "./liveshowReducer";
import { progressReducer } from "./progressReducer";
import { onlinesReducer } from "./onlineReducers";
import { errorReducer } from "./errorReducer";
import {
  ERROR,
  ERRORS,
  ERRORS_REMOVE,
  ERROR_REMOVE,
  REMOVE_LOADING,
  REMOVE_LOADINGS,
  SET_LOADING,
  SET_LOADINGS,
  MESSAGE,
  MESSAGE_REMOVE,
  MODAL_ENABLE,
  MODAL_DISABLE,
  TAB,
  TAB_REMOVE,
  MESSAGES,
  MESSAGES_REMOVE,
  NAV_ENABLE,
  NAV_DISABLE,
  PURCHASE_TYPE_REMOVE,
  PURCHASE_TYPE,
  SET_EDIT,
  SET_WATCHING,
  EDIT_WATCHING,
  IMAGE_VERSION,
} from "../types";
import { errorsReducer } from "./errorReducers";
import { loadingsReducer } from "./loadingsReducer";
import { loadingReducer } from "./loadingReducer";
import { messageReducer } from "./messageReducer";
import { chatReducer } from "./chatReducer";
import { highlightReducer } from "./highlightReducer";
import { modalReducer } from "./modalReducer";
import { tabReducer } from "./tabReducer";
import { messagesReducer } from "./messagesReducer";
import { profileReducer } from "./profileReducer";
import { mediaReducer } from "./mediaReducer";
import { notificationsReducer } from "./notificationsReducer";
import { sessionReducer } from "./sessionReducer";
import { chatsReducer } from "./chatsReducer";
import { usersOnlineReducer } from "./usersOnlineReducer";
import { privateShowRequestsReducer } from "./privateShowRequestsReducer";
import { isInPrivateReducer } from "./isInPrivateReducer";
import { packagesReducer } from "./packagesReducer";
import { socialsReducer } from "./socialsReducer";
import { usersInChatReducer } from "./usersInChatReducer";
import { userInShowReducer } from "./userInShowReducer";
import { conversationsReducer } from "./conversationsReducer";
import { messagePrivateReducer } from "./messagePrivateReducer";
import { pauseReducer } from "./pauseReducer";
import { binomReducer } from "./binomReducer";
import { pinnedMessagesReducer } from "./pinnedMessagesReducer";
import { navReducer } from "./navReducer";
import { balanceReducer } from "./balanceReducer";
import { modelEarningsReducer } from "./modelEarningsReducer";
import { connectionReducer } from "./connectionReducer";
import { purchaseTypeReducer } from "./PurchaseTypeReducer";
import { poolsReducer } from "./poolsReducer";
import { postReducer } from "./postReducer";
import { postsReducer } from "./postsReducer";
import { pricingReducer } from "./pricingReducer";
import { EventsReducer } from "./eventsReducer";
import { memberBonusReducer } from "./memberBonusReducer";
import { maintanceReducer } from "./maintanceReducer";
import { mediasReducer } from "./mediasReducer";
import { EditModelReducer } from "./EditModelReducer";
import { galleryReducer } from "./galleryReducer";
import { WatchModelMediaRedcuer } from "./WatchModelMediaRedcuer";
import { ImageVersionReducer } from "./ImageVersionReducer";
import { socketConnectionReducer } from "./socketConnectionReducer";
import { myPoolsReducer } from "./myPoolsReducer";
import { magicTabReducer } from "./magicTabReducer";
import { magicReducer } from "./magicReducer";

export const rootReducer = combineReducers({
  session: userReducer,
  model: modelReducer,
  model_earnings: modelEarningsReducer,
  models: modelsReducer,
  cameras: camerasReducer,
  camera: cameraReducer,
  progress: progressReducer,
  liveshow: liveshowReducer,
  liveshows: liveshowsReducer,
  onlines: onlinesReducer,
  error: errorReducer,
  errors: errorsReducer,
  loading: loadingReducer,
  loadings: loadingsReducer,
  chat: chatReducer,
  highlight: highlightReducer,
  message: messageReducer,
  messages: messagesReducer,
  modal: modalReducer,
  tab: tabReducer,
  purchaseType: purchaseTypeReducer,
  profile: profileReducer,
  media: mediaReducer,
  gallery: galleryReducer,
  notifications: notificationsReducer,
  requests: sessionReducer,
  chats: chatsReducer,
  usersOnline: usersOnlineReducer,
  privateShowRequests: privateShowRequestsReducer,
  isInPrivateShow: isInPrivateReducer,
  packages: packagesReducer,
  socials: socialsReducer,
  usersInChat: usersInChatReducer,
  userInShow: userInShowReducer,
  conversations: conversationsReducer,
  privateMessages: messagePrivateReducer,
  isPaused: pauseReducer,
  binom: binomReducer,
  pinnedMessages: pinnedMessagesReducer,
  nav: navReducer,
  balance: balanceReducer,
  connection: connectionReducer,
  pools: poolsReducer,
  myPools: myPoolsReducer,
  post: postReducer,
  posts: postsReducer,
  pricing: pricingReducer,
  events: EventsReducer,
  memberJoinBonus: memberBonusReducer,
  maintance: maintanceReducer,
  medias: mediasReducer,
  edit: EditModelReducer,
  watching: WatchModelMediaRedcuer,
  imageVersion: ImageVersionReducer,
  socketConnectionId: socketConnectionReducer,
  magic: magicReducer,
  magicTab: magicTabReducer,
});

export const showNav = (data, content = null) => {
  return {
    type: NAV_ENABLE,
    payload: { data },
    content: { content },
  };
};

export const removeNav = () => {
  return {
    type: NAV_DISABLE,
  };
};

export const showModal = (data, content = null) => {
  return {
    type: MODAL_ENABLE,
    payload: { data },
    content: { content },
  };
};

export const hideModal = (data) => {
  return {
    type: MODAL_DISABLE,
    payload: { data },
  };
};

export const setTab = (data) => {
  return {
    type: TAB,
    payload: { data },
  };
};

export const removeTab = () => {
  return {
    type: TAB_REMOVE,
  };
};

export const setPurchaseType = (data) => {
  return {
    type: PURCHASE_TYPE,
    payload: { data },
  };
};

export const setImageVersion = (data) => {
  return {
    type: IMAGE_VERSION,
    payload: { data },
  };
};

export const removePurchaseType = () => {
  return {
    type: PURCHASE_TYPE_REMOVE,
  };
};

export const setMessages = (text, type) => {
  return {
    type: MESSAGES,
    payload: { text, type },
  };
};
export const removeMessages = () => {
  return {
    type: MESSAGES_REMOVE,
  };
};

export const setMessage = (message, type, position, isMoney) => {
  return {
    type: MESSAGE,
    payload: { message, type, position, isMoney },
  };
};
export const removeMessage = () => {
  return {
    type: MESSAGE_REMOVE,
  };
};

export const setError = (data) => {
  return {
    type: ERROR,
    payload: { data },
  };
};

export const setErrors = (data) => {
  return {
    type: ERRORS,
    payload: { data },
  };
};

export const removeError = (data) => {
  return {
    type: ERROR_REMOVE,
    payload: { data },
  };
};

export const removeErrors = () => {
  return {
    type: ERRORS_REMOVE,
  };
};

export const setLoading = (data) => {
  return {
    type: SET_LOADING,
    payload: { data },
  };
};
export const setEditProfile = (data) => {
  return {
    type: SET_EDIT,
    payload: { data },
  };
};

export const removeLoading = (data) => {
  return {
    type: REMOVE_LOADING,
    payload: { data },
  };
};

export const setLoadings = (data) => {
  return {
    type: SET_LOADINGS,
    payload: { data },
  };
};

export const removeLoadings = (data) => {
  return {
    type: REMOVE_LOADINGS,
    payload: { data },
  };
};

export const setWatchingMedia = (data) => {
  return {
    type: SET_WATCHING,
    payload: { data },
  };
};
export const EditWatchingMedia = (data) => {
  return {
    type: EDIT_WATCHING,
    payload: { data },
  };
};
