import { PURCHASE_TYPE, PURCHASE_TYPE_REMOVE } from '../types'

export const purchaseTypeReducer = (state = 'account', action) =>{
  const {type, payload} = action

  switch (type) {
    case PURCHASE_TYPE: {
      let {data} = payload
      return data
    }
    case PURCHASE_TYPE_REMOVE: {
      return {}
    }
    default: {
      return state
    }
  }
}
