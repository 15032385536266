import { SET_PRIVATE_SHOW_REQUEST, REMOVE_PRIVATE_SHOW_REQUEST, SET_PRIVATE_SHOW_REQUESTS, REMOVE_PRIVATE_SHOW_REQUEST_BY_ID } from '../types'

const initialState = [];
export const privateShowRequestsReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case SET_PRIVATE_SHOW_REQUESTS: {
      const { data } = payload
      return data
    }
    case SET_PRIVATE_SHOW_REQUEST: {
      const { data } = payload
      if(state.some(item => data.publicLiveshowId === item.publicLiveshowId))
        return state
      return [...state, data]
    }
    case REMOVE_PRIVATE_SHOW_REQUEST: {
      const { data } = payload
      return state.filter((request) => request.publicLiveshowId !== data);
    }
    case REMOVE_PRIVATE_SHOW_REQUEST_BY_ID: {
      const { data } = payload
      return state.filter((request) => request.id !== data);
    }
    default: {
      return state
    }
  }
}
