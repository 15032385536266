import { PAUSE_VIDEO, START_VIDEO } from "../types";

const initialState = null;

export const pauseReducer = (state = initialState, action) =>{
  const {type} = action

  switch (type) {
    case PAUSE_VIDEO:
      return true;
    case START_VIDEO:
      return false;
    default:
      return state;
  }
}
