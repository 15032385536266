/* eslint-disable array-callback-return */
import { MAGIC, MAGIC_INCREMENT_VOTE } from "../types";

export const magicReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case MAGIC: {
      const { data } = payload;
      return data;
    }
    case MAGIC_INCREMENT_VOTE: {
      const { data } = payload;
      let newState = Object.assign({}, state);
      // eslint-disable-next-line array-callback-return
      newState.stages.map((stage) => {
        if (stage.id === data.stage.id) {
          stage.tasks.map((task) => {
            if (task.id === data.id) {
              task.votes_count += 1;
            }
          });
        }
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
