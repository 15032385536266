import { SET_LOADINGS, REMOVE_LOADINGS } from '../types'

const initialState = [];

export const loadingsReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case SET_LOADINGS: {
      const { data } = payload
      return [...state,
        {
          name: data,
          isLoading: true
        }
      ];
    }
    case REMOVE_LOADINGS: {
      const { data } = payload
      return state.filter(loading => loading.name !== data)
    }
    default: {
      return state
    }
  }
}
