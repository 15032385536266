import { SET_LOADING, REMOVE_LOADING, SET_EDIT } from "../types";

export const EditModelReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EDIT: {
      let { data } = payload;
      return data;
    }

    default: {
      return state;
    }
  }
};
