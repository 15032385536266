import {
  API_CREATE_POOL_ROUTE,
  API_FETCH_LIVESHOW_POOLS,
  API_FETCH_MY_MODEL_POOLS,
  API_FETCH_POOLS,
} from "../../utils/consts";
import { setMessage } from "../reducers/rootReducer";
import {
  MYPOOLS,
  MYPOOLS_ADD_ONE,
  MYPOOLS_REMOVE,
  POOL,
  POOLS,
} from "../types";

export const addPool = (data) => {
  return {
    type: POOL,
    payload: { data },
  };
};
export const setPools = (data) => {
  return {
    type: POOLS,
    payload: { data },
  };
};

export const setMyModelPools = (data) => {
  return {
    type: MYPOOLS,
    payload: { data },
  };
};
export const addMyModelPool = (data) => {
  return {
    type: MYPOOLS_ADD_ONE,
    payload: { data },
  };
};
export const deleteMyModelPools = () => {
  return {
    type: MYPOOLS_REMOVE,
  };
};

export const AccomplishPool =
  (id, type, liveshowId = null) =>
  async (dispatch) => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        credentials: "include",
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          "/api/v1/models/pools/" +
          id +
          "/edit/accomplish",
        options
      );
      const data = await response.json();
      if (data.type === "success") {
        dispatch(updatePool(type, liveshowId));
        dispatch(setMessage(data.message, data.type));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const createPool = (pool) => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_CREATE_POOL_ROUTE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        credentials: "include",
        body: JSON.stringify(pool),
      }
    );
    const data = await response.json();
    if (data) {
      dispatch(setMessage(data.message, data.type));
      dispatch(updatePool("all", null));
    }
  } catch (error) {
    console.log(error);
  }
};

export const ExtendPool =
  (item, id, type, liveshowId = null) =>
  async (dispatch) => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(item),
        credentials: "include",
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          "/api/v1/models/pools/" +
          id +
          "/edit",
        options
      );
      const data = await response.json();
      if (data.type === "success") {
        dispatch(updatePool(type, liveshowId));
        dispatch(setMessage(data.message, data.type));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updatePool =
  (type, liveshowId = null) =>
  async (dispatch) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          (type === "all"
            ? API_FETCH_POOLS
            : type === "myModel"
            ? API_FETCH_MY_MODEL_POOLS
            : API_FETCH_LIVESHOW_POOLS + liveshowId + "/pools"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          credentials: "include",
        }
      );
      const data = await response.json();
      if (data) {
        if (type === "myModel") dispatch(setMyModelPools(data.data.pools));
        else dispatch(setPools(data.data.pools));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getMyModelPools = () => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_MY_MODEL_POOLS,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        credentials: "include",
      }
    );
    const data = await response.json();
    if (data) {
      dispatch(setMyModelPools(data.data.pools));
    }
  } catch (error) {
    console.log(error);
  }
};
