import { MODELS } from '../types'

const initialState = [];
export const modelsReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case MODELS: {
      const { data } = payload
      state = initialState;
      return [...state, ...data]
    }
    default: {
      return state
    }
  }
}
