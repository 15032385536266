import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { getModelEarnings } from "../../redux/actions/modelAction";
import { connect, useDispatch } from "react-redux";
import {
  hideModal,
  removeErrors,
  showModal,
} from "../../redux/reducers/rootReducer";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useTranslation } from "react-i18next";
import CloseButton from "../../UI/CloseButton";

const mapStateToProps = (state) => {
  return {
    model_earnings: state.model_earnings,
    modal: state.modal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getModelEarnings: (month, year) => dispatch(getModelEarnings(month, year)),
  };
};
function ModelEarningsModal({ modal, model_earnings, getModelEarnings }) {
  const dispatch = useDispatch();

  const handleOpenEarnings = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      modal && modal.name === "earnings"
        ? dispatch(hideModal("earnings"))
        : dispatch(showModal("earnings"));
    }
    dispatch(removeErrors());
  };

  const { t } = useTranslation();
  React.useEffect(() => {
    getModelEarnings(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    minWidth: 300,
    minHeight: 600,
    maxHeight: 1000,
    width: "100%",
    bgcolor: "#171717",
    textAlign: "center",
    margin: "0 auto",
    p: 4,
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(0 0 0)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ffffff14",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#555555",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#292929",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const data = [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ];

  const [month, setMonth] = React.useState(
    model_earnings && model_earnings.current_month
  );
  const [year, setYear] = React.useState(
    model_earnings && model_earnings.current_year
  );

  function getIndex(name) {
    return data.findIndex((obj) => obj.name === name);
  }
  function cameraChangeButtonForward() {
    var index = getIndex(model_earnings.current_month);
    var nextid = index + 1;
    if (nextid === data.length) {
      nextid = 0;
      model_earnings.current_year += 1;
      setYear(model_earnings.current_year);
    }
    model_earnings.current_month = data[nextid].name;
    setYear(model_earnings.current_year);
    setMonth(data[nextid].name);
    /*  dispatch(changeMainCamera(cameras[nextid])); */
    /* handleClose(); */
  }
  function cameraChangeButtonBack() {
    var index = getIndex(model_earnings.current_month);
    var nextid = index - 1;
    if (nextid < 0) {
      nextid = data.length - 1;
      model_earnings.current_year -= 1;
      setYear(model_earnings.current_year);
    }
    model_earnings.current_month = data[nextid].name;
    setYear(model_earnings.current_year);
    setMonth(data[nextid].name);
    /* handleClose(); */
  }
  React.useEffect(() => {
    if (month !== undefined || year !== undefined) {
      getModelEarnings(month, year);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={modal && modal.name === "earnings"}
      onClose={handleOpenEarnings}

      //onClose={handleClose}
    >
      <Box
        sx={style}
        style={{
          outline: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "80%",
        }}
      >
        <CloseButton onClick={handleOpenEarnings} />
        <Box className="DatePicker">
          <Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
            <Button
              onClick={() => cameraChangeButtonBack()}
              startIcon={<ArrowBackIosIcon />}
              className="ModelProfilePage_arrow_back"
              variant="contained"
            >
              <span style={{ position: "relative", top: "1px" }}>
                {t("Previous")}
              </span>
            </Button>
          </Box>
          <Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
            <Box className="Date">
              <Typography
                variant="h6"
                component="div"
                style={{
                  color: "white",
                  minWidth: "170px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {model_earnings && model_earnings.current_month}
                &nbsp;
                {model_earnings && model_earnings.current_year}
              </Typography>
            </Box>
          </Box>
          <Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
            <Button
              onClick={() => cameraChangeButtonForward()}
              endIcon={<ArrowForwardIosIcon />}
              className="ModelProfilePage_arrow_next"
              variant="contained"
            >
              <span style={{ position: "relative", top: "1px" }}>
                {t("Next")}
              </span>
            </Button>
          </Box>
        </Box>
        <TableContainer className="SchrollBar ModalTable" component={Paper}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell style={{ borderBottom: "none" }} align="left">
                  {t("Type of tips")}
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "none" }} align="left">
                  {t("Credits")}
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "none" }} align="left">
                  {t("Sender")}
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "none" }} align="left">
                  {t("Time")}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {model_earnings &&
                model_earnings.earnings &&
                model_earnings.earnings.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      style={{
                        borderBottom: "none",
                        color: "#78F73B",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {row.item}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none", color: "#FFD800" }}
                    >
                      {row.tokens === null ? 0 : row.tokens}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none", color: "#FFD800" }}
                    >
                      {row.payFrom}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none", color: "#FFD800" }}
                    >
                      {row.createdAt}
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelEarningsModal);
