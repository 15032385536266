export default class StringHelper {
    static replaceSpacesWithDashes = (str) => {
        if (!str) {
            return;
        }
        return str.replace(/\s+/g, '-');
    }
    static replaceDashesWithSpaces = (str) => {
        if (!str) {
            return;
        }
        return str.replace(/-/g, ' ');
    }
}