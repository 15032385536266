import { SOCIALS } from '../types'

const initialState = [];
export const socialsReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case SOCIALS: {
      const { data } = payload
      return data
    }
    default: {
      return state
    }
  }
}
