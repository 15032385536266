export const palette = {
    primary: {
        main: '#77f73b',
    },
    secondary: {
        main: '#ed2779',
    },
    third: {
        main: '#FFD800',
        contrastText: '#000'
    },
    purple: {
        main: '#9E24EB',
        light: '#9E24EB',
        dark: '#9E24EB',
        contrastText: '#FFF'
    },
    white: {
        main: '#FFF',
        light: '#FFF',
        dark: '#a1a1a1',
        contrastText: '#000'
    },
    grey: {
        main: '#292929',
        light: '#292929',
        dark: '#292929',
        contrastText: '#fff'
    },
    black: {
        main: '#000',
        light: '#000',
        dark: '#000',
        contrastText: '#FFF'
    },
    gradient: {
        main: 'transparent linear-gradient(95deg, #FFD800 0%, #77F73B 46%, #ED2779 100%) 0% 0% no-repeat padding-box',
        light: 'transparent linear-gradient(95deg, #FFD800 0%, #77F73B 46%, #ED2779 100%) 0% 0% no-repeat padding-box',
        dark: 'transparent linear-gradient(95deg, #FFD800 0%, #77F73B 46%, #ED2779 100%) 0% 0% no-repeat padding-box',
        contrastText: '#FFF'
    },
    paper: {
        main: '#171717',
        light: '#171717',
        dark: '#171717',
        contrastText: '#FFF'
    },
    text: {
        primary: 'rgb(255, 255, 255)',
        secondary: 'rgb(190, 190, 190)',
        disabled: 'rgba(255, 255, 255, 0.5)',
    },
    divider: '#55585c',
    background: {
        default: '#000',
        paper: 'rgb(30, 30, 30)',
    }
};