import { USERS_ONLINE } from '../types'
const initialState = [];
export const usersOnlineReducer = (state = initialState, action) =>{
  const {type, payload} = action
  
  switch (type) {
    case USERS_ONLINE: {
      const { data } = payload
      state = initialState;
      return data
    }
    default: {
      return state
    }
  }
}
