import {
  Button,
  ButtonGroup,
  Input,
  MobileStepper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  decrementCredits,
  incrementPool,
} from "../../../redux/actions/tipsActions";
import { setMessage } from "../../../redux/reducers/rootReducer";
import axios from "../../../utils/axios";
import { API_SEND_TIPS_ROUTE } from "../../../utils/consts";
import EditShow from "./EditShow";
import clock from "./clock.svg";
import moment from "moment";
import PerformerBox from "./PerformerBox";
import { setBalance } from "../../../redux/actions/environmentAction";

export default function Pool({ pool, liveshow, pool_id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stepRef = useRef();
  const PoolContainer = React.useRef();
  const user = useSelector((state) => state.session.user);
  const [tipAmount, setTipAmount] = React.useState(25);

  React.useEffect(() => {
    const els = document.getElementsByClassName("MuiLinearProgress-bar");
    if (!els) return;
    for (let index = 0; index < els.length; index++) {
      const element = els[index];
      var numb = element.style.transform.match(/-\d+\d/g);

      if (numb) numb = numb.join("");

      if (numb > 0 || !numb) {
        element.style.transform = "translateX(0)";
      }
    }
  });

  const send = async (amount, type, id) => {
    try {
      let formData = {
        tokens: amount,
        type: type,
      };
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          API_SEND_TIPS_ROUTE +
          (id ? "/" + id : ""),
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
      if (res.status === 201) {
        dispatch(setMessage(res.data.message));
        dispatch(setBalance(res.data.balance));
        dispatch(incrementPool({ id: pool.id, amount: amount }));
      } else {
        res.data.message
          ? dispatch(setMessage(res.data.message, "error", null, true))
          : res.data.errors &&
            dispatch(setMessage(res.data.errors[0], "error"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TipToPool = (e) => {
    e.preventDefault();
    if (!user) {
      navigate("/register");
      return;
    }
    send(tipAmount, "pool", pool.id);
  };

  const [className, setClassName] = useState("grey-on-hover");

  React.useEffect(() => {
    pool_id === pool.id &&
      document.getElementById("1").scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
        alignToTop: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool_id]);

  const [time, setTime] = React.useState(Date.now());

  // React.useEffect(() => {
  //     const interval = setInterval(() => setTime(Date.now()), 1000);
  //     return () => {
  //         clearInterval(interval);
  //     };
  // }, []);

  function getTimeStamp(time, dateEnd) {
    var date = new Date(dateEnd * 1000);
    const endDate = moment(date);
    const startDate = moment(time);
    const diff = endDate.diff(startDate);
    var diffDuration = moment.duration(diff);
    const days = diffDuration.days();
    const hours = diffDuration.hours() + days * 24; // get hours with getHours method
    const minutes = diffDuration.minutes(); // get minutes with getMinutes method
    const seconds = diffDuration.seconds(); // get seconds with getSeconds method
    if ((hours || minutes || seconds) < 0) {
      return "00:00:00";
    }
    const timeString = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    return timeString; // finally, join to a time string
  }
  function pad(num) {
    return ("0" + num).slice(-2);
  }

  return (
    <Box
      id={pool_id && pool.id === pool_id && "1"}
      ref={PoolContainer}
      className={pool_id && pool.id === pool_id ? className : ""}
      key={"p" + pool.id}
      sx={{
        borderBottom: "1px solid #464646",
        padding: "10px 17px",
        width: "100%",
      }}
    >
      <Box
      className='Pool_scroll'
        sx={{
          marginBottom: "8px",
          display: "flex",
          gap: "15px",
          overflowX: "auto",
        }}
      >
        {pool &&
          pool.performers.map((model, i) => {
            return <PerformerBox key={"pref" + i} model={model} />;
          })}
        {user &&
          user.role === "model" &&
          pool.performers.filter((e) => e.id === user.id).length > 0 && (
            <EditShow pool={pool} liveshow={liveshow} />
          )}
      </Box>
      <Typography sx={{ fontSize: "14px" }}>
        {pool && pool.description}
      </Typography>
      <Box
        className="poolProgress"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "7px",
          marginTop: "2px",
        }}
      >
        <MobileStepper
          variant="progress"
          steps={pool.credits_target}
          position="static"
          ref={stepRef}
          activeStep={pool.credits_current}
          className="progress-primary"
        />
        <Typography sx={{ fontSize: "12px" }}>
          {pool.credits_current} of {pool.credits_target}
        </Typography>
        <ButtonGroup
          variant="contained"
          sx={{ width: "fit-content" }}
          aria-label="outlined primary button group"
        >
          <Input
            className="input-group-primary"
            type="number"
            variant="outlined"
            placeholder="25"
            onChange={(e) => setTipAmount(e.target.value)}
            disableUnderline
            value={tipAmount}
            sx={{
              background: "white",
              borderRadius: "5px 0px 0px 5px",
              width: "fit-content",
              color: "black",
              textAlign: "center",
              fontSize: "12px",
              height: "26px",
            }}
          />
          <Button
            className="btn-purple"
            onClick={(e) => TipToPool(e)}
            sx={{
              borderBottomLeftRadius: "0",
              borderTopLeftRadius: "0",
              fontSize: "14px",
              height: "26px",
            }}
          >
            Tip
          </Button>
        </ButtonGroup>
      </Box>
      {/* <Typography style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                <img src={clock} style={{ paddingRight: '10px', marginBottom: '2px' }} alt='clock' />
                {pool && pool.valid_to &&
                    getTimeStamp(time, pool.valid_to)}
            </Typography> */}
    </Box>
  );
}
