import { NAV_ENABLE, NAV_DISABLE } from '../types'

export const navReducer = (state = false, action) =>{

  const {type, payload, content} = action
  switch (type) {
    case NAV_ENABLE: {
      let { data } = payload;
      return {
        name: data,
        data: content.content
      }
    }
    case NAV_DISABLE: {
        return {}
    }
    default: {
      return state
    }
  }
}
