import axios from "../../utils/axios";
import { API_SEND_TIPS_ROUTE } from "../../utils/consts";
import { setErrors, setMessage } from "../reducers/rootReducer";
import { DECREMENT_CREDITS, INCREMENT_POOL } from "../types";
import { setBalance } from "./environmentAction";

export const decrementCredits = (data) => {
  return {
    type: DECREMENT_CREDITS,
    payload: { data },
  };
};

export const incrementPool = (data) => {
  return {
    type: INCREMENT_POOL,
    payload: { data },
  };
};

export const sendTips =
  (amount, type, id, chatId = null) =>
  async (dispatch) => {
    try {
      let formData = {
        tokens: amount,
        type: type,
        chatId: chatId,
      };
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          API_SEND_TIPS_ROUTE +
          (id ? "/" + id : ""),
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        }
      );
      if (res.status === 201) {
        dispatch(setMessage(res.data.message));
        dispatch(setBalance(res.data.balance));
      } else {
        res.data.message
          ? dispatch(setMessage(res.data.message, "error", null, true))
          : res.data.errors && dispatch(setErrors(res.data.errors));
      }
    } catch (error) {
      console.log(error);
    }
  };
