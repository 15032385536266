import { API_FETCH_CAMERAS_ROUTE, API_FETCH_CAMERA_ROUTE } from "../../utils/consts"
import { headers } from "../config"
import { setErrors, setMessage } from "../reducers/rootReducer"
import { CAMERA, CAMERAS, PAUSE_VIDEO, REMOVE_CAMERA, START_VIDEO } from "../types"

const allCameras = (data) => {
  return {
    type: CAMERAS,
    payload: { data }
  }
}

const oneCamera = (data) => {
  return {
    type: CAMERA,
    payload: { data }
  }
}

export const setCurrentCamera = (data) => {
  return {
    type: CAMERA,
    payload: { data }
  }
}

export const removeCamera = () => {
  return {
    type: REMOVE_CAMERA
  }
}

export const pauseVideo = () => {
  return {
    type: PAUSE_VIDEO,
  };
};

export const startVideo = () => {
  return {
    type: START_VIDEO,
  };
};

export const changeMainCamera = (data) => {
  return {
    type: CAMERA,
    payload: { data }
  }
}

export const getCamera = (id) => async (dispatch) => {
  try {
    const request = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMERA_ROUTE + id, {
      method: "GET",
      headers: headers,
    })
    const response = await request.json()
    if (response.data) {
      dispatch(oneCamera(response.data))
    } else {
      (response.message ?
        dispatch(setMessage(response.message, 'error')) :
        (response.errors && 
          dispatch(setErrors(response.errors))
        )
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export const getCameras = () => async (dispatch) => {
  try {
    const request = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMERAS_ROUTE, {
      method: "GET",
      headers: headers
    })
    const response = await request.json();
    if (response.data) {
      dispatch(allCameras(response.data))
    } else {
      (response.message ?
        dispatch(setMessage(response.message, 'error')) :
        (response.errors && 
          dispatch(setErrors(response.errors))
        )
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export const cameraModel = (item) => async (dispatch) => {

}

