import {
  INCREMENT_POOL,
  MYPOOLS,
  MYPOOLS_ADD_ONE,
  MYPOOLS_REMOVE,
} from "../types";

export const myPoolsReducer = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case MYPOOLS: {
      const { data } = payload;
      return data;
    }
    case MYPOOLS_ADD_ONE: {
      const { data } = payload;
      let newState = Object.assign([], state);
      return [...newState, data];
    }
    case MYPOOLS_REMOVE: {
      return [];
    }

    case INCREMENT_POOL: {
      const { data } = payload;

      let newState = Object.assign([], state);

      // eslint-disable-next-line array-callback-return
      newState.map((pool, i) => {
        if (pool.id === data.id) {
          newState[i].credits_current += parseInt(data.amount);
        }
      });

      return newState;
    }
    default: {
      return state;
    }
  }
};
