import {  PACKAGES } from '../types'

const initialState = [];
export const packagesReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case PACKAGES: {
      const { data } = payload
      return data
    }
    default: {
      return state
    }
  }
}
