/* eslint-disable array-callback-return */
import { CORE_API_CHAT_ROUTE, CORE_API_LOGIN_ROUTE, CORE_API_GET_MESSAGES_ROUTE, CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN, CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD, CORE_API_SEND_MESSAGE_ROUTE, API_FETCH_CONVERSATION_WITH, CORE_API_CHAT_TOKEN_ROUTE } from "../../utils/consts"
import { removeLoadings, setLoadings, showNav } from "../reducers/rootReducer"

import { PRIVATE_MESSAGES, ADD_MESSAGE_TO_PRIVATE_MESSAGES, PRIVATE_MESSAGES_REMOVE, CONVERSATION_MOVE_TOP } from "../types"
import { getConversations } from "./userAction"

const allMessages = (data) => {
  return {
    type: PRIVATE_MESSAGES,
    payload: { data }
  }
}

const addMessage = (data) => {
  return {
    type: ADD_MESSAGE_TO_PRIVATE_MESSAGES,
    payload: { data }
  }
}

const setMessagesToState = (data) => {
  return {
    type: PRIVATE_MESSAGES,
    payload: { data }
  }
}

export const removeMessages = () => {
  return {
    type: PRIVATE_MESSAGES_REMOVE
  }
}

export const conversationMoveTop = (data) => {
  return {
    type: CONVERSATION_MOVE_TOP,
    payload: { data }
  }
}

export function getSocketToken() {

  if (localStorage.getItem("core_api_token") && localStorage.getItem("core_api_token") !== undefined)
    return localStorage.getItem("core_api_token");

  let token = null;
  fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_LOGIN_ROUTE, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    //credentials: 'include',
    body: JSON.stringify({
      login: CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN,
      password: CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD
    })
  })
    .then(response => response.json())
    .then(data => {
      token = data.data.token;
      localStorage.setItem("core_api_token", token);
      return token;
    })
    .catch((error) => {
      return null;
    });

  return token;
}

export const getConversationWith = (userId) => async (dispatch) => {
  try {

    let request = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_CONVERSATION_WITH + userId, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem("access_token")
      },
      credentials: 'include'
    });


    let response = await request.json();

    if (response.success) {
      dispatch(getConversations());
      dispatch(showNav('messages', userId));
    }
  } catch (error) {
    console.log(error)
  }
}

export const getPrivateMessages = (chatId, token) => async (dispatch) => {

  localStorage.setItem('retry_count', 0)
  let count = 0;
  try {
    dispatch(setLoadings('pm'));
    if (!token)
      token = localStorage.getItem("core_api_token");

    let requestMessages = await fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_CHAT_ROUTE + chatId + CORE_API_GET_MESSAGES_ROUTE, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      //credentials: 'include',
      body: JSON.stringify({
        limit: 200,
        onlyOwn: false
      })
    });

    let messages = []
    let responseMessages = await requestMessages.json();
    if (localStorage.getItem("retry_count") < 6) {
      if (responseMessages.success === false) {
        localStorage.setItem('retry_count', count + 1)
        localStorage.removeItem("core_api_token");
        if (!localStorage.getItem('access_token')) {
          token = null;
          fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_LOGIN_ROUTE, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            //credentials: 'include',
            body: JSON.stringify({
              login: CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN,
              password: CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD
            })
          })
            .then(response => response.json())
            .then(async data => {
              token = data.data.token;
              localStorage.setItem("core_api_token", token);
              requestMessages = await fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_CHAT_ROUTE + chatId + CORE_API_GET_MESSAGES_ROUTE, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                //credentials: 'include',
                body: JSON.stringify({
                  limit: 200,
                  onlyOwn: false
                })
              });
              responseMessages = await requestMessages.json();
              if (responseMessages.data) {
                const messagesArray = responseMessages.data.reverse();
                messagesArray.forEach(message => {
                  const unixTime = message.createdAt;
                  const date = new Date(unixTime * 1000);
                  var hours = date.getHours();
                  var minutes = String(date.getMinutes()).padStart(2, '0');
                  messages.push({
                    id: parseInt(message.id),
                    message: message.message,
                    type: message.messageType,
                    banned: message.banned === 0 ? false : true,
                    data: {
                      avatar: message.data.avatar ? message.data.avatar : '',
                      name: message.data.name,
                      userId: message.data.userId,
                      time: chatId === 1 ? '' : hours + ':' + minutes,
                      role: message.data.role,
                      is_pool: message.data.is_pool,
                      pool_id: message.data.itemId ? message.data.itemId : 0
                    }
                  });
                });
                dispatch(allMessages(index(messages)));
                dispatch(removeLoadings('messages'));
              }
            })
        } else {
          requestMessages = await fetch(process.env.REACT_APP_BACKEND_URL + CORE_API_CHAT_TOKEN_ROUTE, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              "Accept": "application/json",
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            //credentials: 'include',
            body: JSON.stringify({
              limit: 200,
              onlyOwn: false
            })
          });
          let responseMessages2 = await requestMessages.json();
          token = responseMessages2.data;
          localStorage.setItem("core_api_token", token);
          if (token) {
            requestMessages = await fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_CHAT_ROUTE + chatId + CORE_API_GET_MESSAGES_ROUTE, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              //credentials: 'include',
              body: JSON.stringify({
                limit: 200,
                onlyOwn: false
              })
            });
            responseMessages = await requestMessages.json()
          }
        }

      }
    }
    if (responseMessages.data) {
      localStorage.setItem('retry_count', 0);
      count = 0;
      const messagesArray = responseMessages.data.reverse();
      messagesArray.forEach(message => {
        const unixTime = message.createdAt;
        const date = new Date(unixTime * 1000);
        var hours = date.getHours();
        var minutes = String(date.getMinutes()).padStart(2, '0');
        messages.push({
          id: parseInt(message.id),
          message: message.message,
          type: message.messageType,
          banned: message.banned === 0 ? false : true,
          data: {
            avatar: message.data.avatar ? message.data.avatar : '',
            name: message.data.name,
            userId: message.data.userId,
            time: chatId === 1 ? '' : hours + ':' + minutes,
            role: message.data.role,
            is_pool: message.data.is_pool,
            pool_id: message.data.itemId ? message.data.itemId : 0,
            event: message.data.event ? message.data.event : '',
            url: message.data.url ? message.data.url : ''
          }
        });
      });
      dispatch(allMessages(index(messages)));
      dispatch(removeLoadings('pm'));
    }
  } catch (error) {
    console.log(error)
    dispatch(removeLoadings('pm'));
  }
}

function index(messages) {
  if (!messages)
    return;
  let newMessages = [];
  messages.map((message, index) => {
    if (message['isPrevious'] !== null) {
      let lastMessage = messages[index - 1];
      message.isPrevious = (lastMessage && message.data.userId === lastMessage.data.userId ? true : false);
      if (message.isPrevious) {
        message.previousSameType = (lastMessage && message.type === lastMessage.type ? true : false);

        if (message.previousSameType) {
          message.previousId = lastMessage.previousId ? lastMessage.previousId : lastMessage.id;
        }

        if (!lastMessage.previousSameType) {
          message.previousId = lastMessage.id;
        }
      }
    }
    newMessages.push(message);
  })
  return newMessages;
}

export const sendMessage = (messg, chatId) => async (dispatch) => {
  try {
    let token = localStorage.getItem("core_api_token");
    //const request
    await fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_CHAT_ROUTE + chatId + CORE_API_SEND_MESSAGE_ROUTE, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      //credentials: 'include',
      body: JSON.stringify(messg)
    })
  }
  catch (error) {
    console.log(error)
  }
}

export const appendMessageToState = (messg) => async (dispatch) => {
  dispatch(addMessage(messg));
}

export const setMessages = (messages) => async (dispatch) => {
  dispatch(setMessagesToState(messages));
}