import { SET_USER_IN_SHOW } from '../types'

const initialState = {};

export const userInShowReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {

    case SET_USER_IN_SHOW: {
      let { data } = payload
      return data
    }
    
    default: {
      return state
    }
  }
}
