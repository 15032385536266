export const headers_auth = (typeof window !== 'undefined' && localStorage.getItem("access_token"))? {
    'Content-Type': 'application/json',
    "Accept": "application/json",
    'Authorization': `Bearer ` + localStorage.getItem("access_token")
} : {
    'Content-Type': 'application/json',
    "Accept": "application/json",
}

export const headers = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
}