import { REMOVE_SOCKET_CONNECTION, SOCKET_CONNECTION } from '../types'

export const socketConnectionReducer = (state = null, action) =>{
  const {type, payload} = action

  switch (type) {
    case SOCKET_CONNECTION: {
      const { data } = payload
      return data
    }
    case REMOVE_SOCKET_CONNECTION: {
        return null
      }
    default: {
      return state
    }
  }
}
