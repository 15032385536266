import { CONVERSATIONS, CONVERSATIONS_REMOVE, CONVERSATION_MOVE_TOP, CONVERSATION_REMOVE, CONVERSATIONS_REDUCE_UNREAD, CONVERSATIONS_INCREASE_UNREAD } from '../types'

export const conversationsReducer = (state = [], action) => {
  const { type, payload } = action

  switch (type) {
    case CONVERSATIONS: {
      const { data } = payload;
      let unread = 0;
      for (let index = 0; index < data.length; index++) {
        const conversation = data[index];
        unread += conversation.unreadCount;
      }
      data.unread = unread;
      return data;
    }

    case CONVERSATIONS_REDUCE_UNREAD: {
      const { data } = payload;
      
      let newState = Object.assign([], state);
      
      if(newState.unread <= 0)
        return newState;

      newState.unread -= data.unreadCount;

      for (let index = 0; index < newState.length; index++) {
        if(newState[index].conversation.id === data.conversation.id){
          newState[index].unreadCount = 0;
        }
      }
      return newState;
    }

    case CONVERSATIONS_INCREASE_UNREAD: {
      const { data } = payload;
      let newState = Object.assign([], state);

      newState.unread -= data.unreadCount;

      for (let index = 0; index < newState.length; index++) {

        if(newState[index].conversation.id === data.conversation.id)
          newState[index] = data;
      }
      return newState;
    }

    case CONVERSATIONS_REMOVE: {
      return [];
    }

    case CONVERSATION_REMOVE: {
      const { data } = payload;
      return state.filter(item => data !== item.conversation.id)
    }

    case CONVERSATION_MOVE_TOP: {
      const { data } = payload;

      let newState = Object.assign([], state);

      newState = newState.filter(item => item !== data);
      newState.unshift(data);
      return newState
    }

    default: {
      return state
    }
  }
}
