import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeMessage,
  setPurchaseType,
  setTab,
} from "../redux/reducers/rootReducer";
import { Snackbar, Typography, IconButton, Box } from "@mui/material";
import { ReactComponent as ErrorFace } from "../assets/images/icons/error_face.svg";
import { useTranslation } from "react-i18next";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  snackbar: {
    backgroundColor: "#fff",
    color: "#000000",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    justifyContent: "space-between",
  },
  closeIcon: {
    fontSize: 20,
    color: "#000000",
    padding: "10px !important",
    marginLeft: "auto",
    position: "relative",
    top: "1px",
  },
}));

export default function SnackbarUI() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useSelector((state) => state.session.user);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeMessage());
  };
  const handleExited = () => {
    dispatch(removeMessage());
  };
  const naviateToCredits = (event) => {
    dispatch(removeMessage());
    if (!user) {
      navigate("/login");
      return;
    }
    dispatch(setTab("credits"));
    dispatch(setPurchaseType("credits"));
    navigate("/account");
  };

  const message = useSelector((state) => state.message);

  if (
    message &&
    typeof message === "object" &&
    Object.keys(message).length > 0
  ) {
    return (
      <Snackbar
        open={Object.keys(message).length > 0}
        autoHideDuration={5000}
        TransitionProps={{ onExited: handleExited }}
        onClose={handleClose}
        anchorOrigin={
          message.type === "warning"
            ? { vertical: "center", horizontal: "center" }
            : message.position
            ? { vertical: "bottom", horizontal: message.position }
            : { vertical: "bottom", horizontal: "left" }
        }
        sx={{
          top:
            message.type !== "warning" && !message.position && !isMobile
              ? user?.role === "model"
                ? "120px !important"
                : "30px !important"
              : "",
          bottom:
            message.type !== "warning" &&
            !message.position &&
            !isMobile &&
            "unset !important",
          left:
            message.type !== "warning" &&
            !message.position &&
            !isMobile &&
            "22% !important",
          right:
            message.type !== "warning" &&
            !message.position &&
            !isMobile &&
            "unset !important",
          ".MuiPaper-root": {
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0px 3px 6px #00000029",
            minWidth: 320,
            padding: "6px 20px",
            width: "100%",
          },
          "& .MuiSnackbarContent-message": {
            width: "100%",
          },
        }}
        message={
          <Box className={classes.snackbar}>
            {message.type !== "error" && (
              <ThumbUpAltOutlinedIcon
                style={{
                  width: "30px",
                  height: "30px",
                  fill: theme.palette.secondary.main,
                }}
              />
            )}
            {message.type === "error" && (
              <ErrorFace
                style={{
                  width: "30px",
                  height: "30px",
                  fill: theme.palette.secondary.main,
                }}
              />
            )}
            <Box className="flex column" sx={{ mr: 2 }}>
              {message.type === "error" && (
                <Typography variant="body1" fontWeight="bold" color="inherit">
                  {t("Whoops")}
                </Typography>
              )}
              <Typography
                variant="body1"
                color="inherit"
                className="font EffraRegular"
                fontWeight="bold"
              >
                {message.text}
              </Typography>
            </Box>
            <IconButton
              size="small"
              aria-label="close"
              onClick={message.isMoney ? naviateToCredits : handleClose}
              color="inherit"
              className={classes.closeIcon}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                color="inherit"
                className="font GilroyBold"
              >
                {message.isMoney ? t("BUY") : t("ОК")}
              </Typography>
            </IconButton>
          </Box>
        }
      />
    );
  }

  return <></>;
}
