import { MODEL_MEDIA, MODEL_MEDIA_REMOVE } from '../types'
const initialState = {};
export const mediaReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case MODEL_MEDIA: {
      const { data } = payload
      return data;
    }

    case MODEL_MEDIA_REMOVE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
