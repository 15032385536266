import { DECREMENT_PROGRESS, INCREMENT_PROGRESS } from "../types";

const initialState = 0;

export const progressReducer = (state = initialState, action) =>{
  const {type} = action

  switch (type) {
    case INCREMENT_PROGRESS:
      return state + 1;
    case DECREMENT_PROGRESS:
      return Math.max(state - 1, 0);
    default:
      return state;
  }
}
