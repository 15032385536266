import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Grid, Modal, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { deleteAvatar, uploadAvatar } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/reducers/rootReducer";
import AvatarPurchaseCard from "../MyAccount/AvatarPurchaseCard";
import AvatarAvailable from "../MyAccount/AvatarAvailable";
import whxIcon from "../../assets/images/greyWhx.svg";
import { API_FETCH_AVATARS } from "../../utils/consts";
import axios from "../../utils/axios";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
export default function AvatarModal({ modal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const [avatars, setAvatars] = React.useState(null);
  const [availableAvatars, setAvailableAvatars] = React.useState(null);
  const handleCloseAvatar = () => dispatch(hideModal("avatar"));

  function handleAvatarRemove() {
    dispatch(deleteAvatar());
  }
  function handleAvatarUpload(item) {
    dispatch(uploadAvatar(item, session?.user?.id));
  }

  const [addFormData, setAddFormData] = React.useState({});
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);

  async function getAvatars() {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_AVATARS,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.status === 200) {
      setAvatars(res.data.data.avatars);
      setAvailableAvatars(res.data.data.userAvatars);
    }
  }

  React.useEffect(() => {
    getAvatars();
  }, []);

  React.useEffect(() => {
    setImageUrl(
      session?.user?.avatar?.imageSmall
        ? session.user.avatar.imageSmall
        : session?.avatar?.imageSmall
        ? session.avatar.imageSmall
        : null
    );
  }, [session]);

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      const newFormData = { ...addFormData };
      newFormData["myFiles"] = selectedImage;
      setAddFormData(newFormData);
      handleAvatarUpload(newFormData);
    }
    // eslint-disable-next-line
  }, [selectedImage]);

  return (
    <Modal
      open={modal && modal.name === "avatar"}
      onClose={handleCloseAvatar}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal AvatarModal">
        <Grid container spacing={0} style={{ position: "relative" }}>
          <Grid item xs={11}>
            <Typography
              id="modal-modal-description"
              style={{ fontSize: "18px", marginBottom: "15px" }}
            >
              {t("Choose Avatar")}
            </Typography>
          </Grid>

          <Button
            size="small"
            onClick={handleCloseAvatar}
            color="white"
            sx={{
              width: "fit-content",
              minWidth: "unset",
              right: "5px",
              top: "0px",
              position: "absolute",
            }}
          >
            <CloseIcon sx={{ height: "22px", width: "22px" }} />
          </Button>
        </Grid>
        <Grid className="avatarGrid" container spacing={0}>
          <Grid item xs={12} md={6}>
            <Box
              className="avatarBox"
              sx={{
                maxWidth: 400,
                minWidth: 300,
                height: 220,
                backgroundColor: "#181818",
                borderRadius: "10px",
                padding: "10px 20px 20px",
                margin: "5px",
                color: "#FFFFFF",
              }}
            >
              <Typography variant="h6" style={{ color: "#FFFFFF" }}>
                {t("Current")}
              </Typography>
              <Stack
                spacing={2}
                style={{
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label htmlFor="select-image" style={{ borderRadius: "50%" }}>
                    <Button variant="raised" component="span">
                      <Box
                        mt={2}
                        textAlign="center"
                        style={{
                          width: "100px",
                          height: "100px",
                          backgroundColor: "rgb(41, 41, 41)",
                          borderRadius: "50%",
                        }}
                      >
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            alt={imageUrl}
                            height="100px"
                            width="100px"
                            accept="image/x-png,image/gif,image/jpeg"
                            style={{ borderRadius: "50%" }}
                          />
                        )}
                      </Box>

                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="select-image"
                        multiple
                        type="file"
                        name="myFiles"
                        onChange={(e) => {
                          setSelectedImage(e.target.files[0]);
                        }}
                      />
                    </Button>
                  </label>
                </Box>
              </Stack>
              <Stack
                spacing={2}
                mt={2}
                style={{
                  margin: "10px auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleAvatarRemove}
                  size="small"
                  variant="outlined"
                  style={{ border: "2px solid #707070", color: "#FFFFFF" }}
                  startIcon={<CloseIcon />}
                >
                  {t("Remove")}
                </Button>
              </Stack>
            </Box>
            <Box
              className="avatarBox"
              sx={{
                maxWidth: 500,
                minWidth: 300,
                height: 370,
                backgroundColor: "#181818",
                borderRadius: "10px",
                padding: "10px 0px 20px",
                margin: "5px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#FFFFFF", padding: "0 20px" }}
              >
                {t("Available")}
              </Typography>
              <Box
                sx={{
                  border: "none",
                  boxShadow: "none",
                  height: 320,
                  width: "100%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                {availableAvatars &&
                  availableAvatars.map((avatar) => {
                    return <AvatarAvailable avatar={avatar} />;
                  })}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ boxShadow: "none" }}>
            <Box
              className="avatarBox2"
              sx={{
                maxWidth: 400,
                minWidth: 300,
                maxHeight: 595,
                backgroundColor: "#181818",
                borderRadius: "10px",
                boxShadow: "none",
                padding: "10px 0px 20px",
                margin: "5px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                variant="h6"
                style={{ color: "#FFFFFF", padding: "0 20px" }}
              >
                {t("Purchase")}
              </Typography>
              <Box
                className="SchrollBar"
                style={{
                  border: "none",
                  boxShadow: "none",
                  height: 545,
                  width: "100%",
                  overflow: "auto",
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {(!avatars || avatars.length === 0 || avatars === null) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      bottom: "20px",
                      margin: "auto auto",
                      maxWidth: "175px",
                      height: "115px",
                      textAlign: "center",
                      backgroundImage: "url(" + whxIcon + ")",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Typography sx={{ color: "#777777" }}>
                      {t("Come back soon to buy some sick avatars!")}
                    </Typography>
                  </Box>
                )}
                {avatars &&
                  avatars.map((avatar) => {
                    return <AvatarPurchaseCard avatar={avatar} />;
                  })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
