import { Box, Container, Slide, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { ReactComponent as MaintanceIcon } from "./maintance.svg";
import { useTranslation } from "react-i18next";
export default function Maintance() {
  const { t } = useTranslation();
  const maintance = useSelector(state => state.maintance);

  return (
    <Slide in={maintance} direction="down">
      <Box className="maintance">
        <Container maxWidth="xl">
          <Box className="flex items-center">
            <MaintanceIcon />
            <Box className="flex column" sx={{ marginLeft: "15px" }}>
              <p style={{color:'black'}}>{t("Something’s up…")}</p>
              <p style={{color:'black'}}>
                {t("And we are working on it already. So don’t run away bro!")}
              </p>
            </Box>
          </Box>
        </Container>
      </Box>
    </Slide>
  );
}
