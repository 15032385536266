import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changeMainCamera, removeCamera } from "../redux/actions/cameraAction";
import { removeLiveshow } from "../redux/actions/liveshowAction";
import StringHelper from "../utils/StringHelper";

const PlayerContext = React.createContext();

function PlayerControls({ children }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const camera = useSelector((state) => state.camera);
  const liveshow = useSelector((state) => state.liveshow);
  const cameras = useSelector((state) => state.cameras);

  const cameraRef = React.useRef(camera);
  const liveshowRef = React.useRef(liveshow);
  const camerasRef = React.useRef(cameras);

  React.useEffect(() => {
    liveshowRef.current = liveshow;
  }, [liveshow]);

  React.useEffect(() => {
    cameraRef.current = camera;
  }, [camera]);

  React.useEffect(() => {
    camerasRef.current = cameras;
  }, [cameras]);

  function getIndex(id) {
    if (camerasRef.current) {
      return camerasRef.current.findIndex((obj) => obj.id === id);
    }
  }

  function isLiveshowPage() {
    if (location.pathname.includes("liveshows")) return true;
    else return false;
  }

  function switchNextCamera() {
    var index;
    if (isLiveshowPage()) index = getIndex(liveshowRef.current.room_id);
    else index = getIndex(cameraRef.current.id);
    var nextid = index + 1;
    if (nextid === camerasRef.current.length) {
      nextid = 0;
    }
    let player = document.querySelector("#main_video");
    if (camera?.vr_url) {
      window?.DEO?.pause(player);
    }
    const cam = camerasRef.current[nextid];

    if (isLiveshowCam(cam)) {
      setTimeout(() => {
        dispatch(removeCamera());
      }, 1);
      window.location.href = "/liveshows/" + cam.liveshow_id;
      return;
    }
    dispatch(changeMainCamera(cam));
    if (!isLiveshowPage() && !isLiveshowCam(cam)) {
      StringHelper.replaceSpacesWithDashes(cam.camera_name)
        .toLowerCase()
        .includes("bed")
        ? navigate(
            "/live-cams/bedroom/" +
              StringHelper.replaceSpacesWithDashes(
                cam.camera_name
              ).toLowerCase()
          )
        : navigate(
            "/live-cams/" +
              StringHelper.replaceSpacesWithDashes(
                cam.camera_name
              ).toLowerCase()
          );
      setTimeout(() => {
        dispatch(removeLiveshow());
      }, 1);
      return;
    }

    /* handleClose(); */
  }

  function switchPrevCamera() {
    var index;
    if (isLiveshowPage()) index = getIndex(liveshowRef.current.room_id);
    else index = getIndex(cameraRef.current.id);
    var nextid = index - 1;
    if (nextid < 0) {
      nextid = camerasRef.current.length - 1;
    }
    let player = document.querySelector("#main_video");
    if (camera?.vr_url) {
      window?.DEO?.pause(player);
    }
    const cam = camerasRef.current[nextid];
    if (isLiveshowCam(cam)) {
      setTimeout(() => {
        dispatch(removeCamera());
      }, 1);
      window.location.href = "/liveshows/" + cam.liveshow_id;
      return;
    }
    dispatch(changeMainCamera(cam));
    if (!isLiveshowPage() && !isLiveshowCam(cam)) {
      StringHelper.replaceSpacesWithDashes(cam.camera_name)
        .toLowerCase()
        .includes("bed")
        ? navigate(
            "/live-cams/bedroom/" +
              StringHelper.replaceSpacesWithDashes(
                cam.camera_name
              ).toLowerCase()
          )
        : navigate(
            "/live-cams/" +
              StringHelper.replaceSpacesWithDashes(
                cam.camera_name
              ).toLowerCase()
          );
      setTimeout(() => {
        dispatch(removeLiveshow());
      }, 1);
      return;
    }
  }

  function isLiveshowCam(camera) {
    if (camera.camera_type === "liveShow") {
      return true;
    }
    return false;
  }
  return (
    <PlayerContext.Provider
      value={{
        switchPrevCamera: switchPrevCamera,
        switchNextCamera: switchNextCamera,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
}

export default PlayerContext;

export { PlayerControls };
