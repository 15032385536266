import { MEMBER_BONUS } from '../types'

export const memberBonusReducer = (state = 50, action) =>{
  const {type, payload} = action

  switch (type) {
    case MEMBER_BONUS: {
      const { data } = payload
      return data
    }
    default: {
      return state
    }
  }
}
