import { HIGHLIGHT } from '../types'

export const highlightReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case HIGHLIGHT: {
        const { data } = payload
        state = []
        return data
    }
    default: {
      return state
    }
  }
}
