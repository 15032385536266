import {IMAGE_VERSION} from "../types";

export const ImageVersionReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case IMAGE_VERSION: {
      let { data } = payload;
      return data;
    }

    default: {
      return state;
    }
  }
};
