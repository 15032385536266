import React from "react";
import { useSelector } from "react-redux";
import AvatarModal from "./AvatarModal";
import LushModal from "./LushModal";
import ModelEarningsModal from "./ModelEarningsModal";
import ModelShowsModal from "./ModelShowsModal";
import MyMediaModal from "./MyMediaModal";
import PoolsModal from "./PoolsModal";
import RegistrationModal from "./RegistrationModal";
import RestoreModal from "./RestoreModal";
export default function Modals() {
  const modal = useSelector((state) => state.modal);

  if (modal?.name === "login") return <RegistrationModal isLogin />;
  else if (modal?.name === "register") return <RegistrationModal isRegister />;
  else if (modal?.name === "avatar") return <AvatarModal modal={modal} />;
  else if (modal?.name === "earnings")
    return <ModelEarningsModal modal={modal} />;
  else if (modal?.name === "pools") return <PoolsModal modal={modal} />;
  else if (modal?.name === "lushPairing") return <LushModal modal={modal} />;
  else if (modal?.name === "modelShows")
    return <ModelShowsModal modal={modal} />;
  else if (modal?.name === "modelMedia") return <MyMediaModal modal={modal} />;
  else if (modal?.name === "reset")
    return <RestoreModal reset={true} content={modal.content} />;
  else if (modal?.name === "restore") return <RestoreModal />;
}
