import { FETCH_PRIVATE_CHATS } from '../types'

export const chatsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case FETCH_PRIVATE_CHATS: {
        const { data } = payload;
        state = [];
        return data
    }
    default: {
      return state
    }
  }
}
