import {
  MESSAGES,
  ADD_MESSAGE_TO_MESSAGES,
  MESSAGES_REMOVE,
  REMOVE_MESSAGE,
} from "../types";

const initialState = [];
export const messagesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MESSAGES: {
      const { data } = payload;
      return data;
    }
    case ADD_MESSAGE_TO_MESSAGES: {
      const { data } = payload;
      if (state.length >= 199) {
        state.shift();
      }

      if (state.some((item) => data.id === item.id)) return state;

      let lastMessage = state[state.length - 1]
        ? state[state.length - 1]
        : null;

      if (lastMessage) {
        data.isPrevious =
          lastMessage &&
          data.data &&
          data.data.userId === lastMessage.data.userId
            ? true
            : false;
        if (data.isPrevious) {
          data.previousSameType =
            lastMessage && data.type === lastMessage.type ? true : false;
          if (data.previousSameType) {
            data.previousId = lastMessage.previousId
              ? lastMessage.previousId
              : lastMessage.id;
          } else {
            data.previousId = null;
          }
        }
      }
      return [...state, data];
    }
    case REMOVE_MESSAGE: {
      const { data } = payload;
      return state.filter((item) => item.id !== data.id);
    }
    case MESSAGES_REMOVE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
