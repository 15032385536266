import { useNavigate } from "react-router-dom"
import { API_FETCH_MODEL_ROUTE, API_FETCH_MODELS_ROUTE, API_FETCH_MODELS_ROUTE_ACTIVE, API_FETCH_MODEL_MEDIA_ROUTE, API_MODEL_MEDIA_DELETE_ROUTE, API_MODEL_EARNINGS_ROUTE, API_MODEL_PRICELIST_ROUTE, API_MODEL_VIDEO_DELETE_ROUTE, API_BUY_MODEL_MEDIA_ROUTE } from "../../utils/consts"
import { EditWatchingMedia, removeLoadings, setLoadings, setMessage } from "../reducers/rootReducer"
import { ONLINES, MODEL, MODELS, MODEL_MEDIA, MODEL_MEDIA_REMOVE, MODEL_EARNINGS, PRICING, MODEL_GALLERY, EDIT_GALLERY } from "../types"
import axios from '../../utils/axios'
import { decrementCredits } from "./tipsActions"
import { setBalance } from "./environmentAction"

const allModels = (data) => {
  return {
    type: MODELS,
    payload: { data }
  }
}

export const allOnline = (data) => {
  return {
    type: ONLINES,
    payload: { data }
  }
}

const oneModel = (data) => {
  return {
    type: MODEL,
    payload: { data }
  }
}

const oneModelEarnings = (data) => {
  return {
    type: MODEL_EARNINGS,
    payload: { data },
  };
};

const setModelMedia = (data) => {
  return {
    type: MODEL_MEDIA,
    payload: { data }
  }
}
const setModelGallery = (data) => {
  return {
    type: MODEL_GALLERY,
    payload: { data }
  }
}
const EditModelGallery = (data) => {
  return {
    type: EDIT_GALLERY,
    payload: { data }
  }
}


export const setPricing = (data) => {
  return {
    type: PRICING,
    payload: { data }
  }
}

export const removeModelMedia = () => {
  return {
    type: MODEL_MEDIA_REMOVE
  }
}

export const getModelMedia = (id) => async (dispatch) => {
  dispatch(setLoadings('media'));
  try {

    if (!id)
      return;

    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_MODEL_MEDIA_ROUTE + id + '/media',
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
        },
      },
    )

    if (res.data) {
      dispatch(setModelMedia(res.data))
    }
  } catch (error) {
    console.log(error)
  }
  dispatch(removeLoadings('media'));
}
export const getModelGallery = (id) => async (dispatch) => {
  dispatch(setLoadings('media'));
  try {

    if (!id)
      return;

    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_MODEL_MEDIA_ROUTE + id + '/gallery',
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
      },
    )

    if (res.data) {
      dispatch(setModelGallery(res.data.data))
    }
  } catch (error) {
    console.log(error)
  }
  dispatch(removeLoadings('media'));
}

export const BuyModelGallery = (id,type) => async (dispatch) => {
  try {

    if (!id)
      return;

    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_BUY_MODEL_MEDIA_ROUTE + id ,
      {'type': type},
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
      },
    )

    if (res.data) {
      // dispatch(setModelGallery(res.data))
      dispatch(EditWatchingMedia(res.data.data.video))
      dispatch(EditModelGallery(res.data.data.video))
      dispatch(setBalance(res.data.balance));
      dispatch(setMessage(res.data.message,res.data.type))
    }
  } catch (error) {
    console.log(error)
  }
}
export const filterModelGallery = ({ id,show_photos, show_videos }) => async (dispatch) => {
  try {
      dispatch(setLoadings('media'));
      const res = await axios.get(process.env.REACT_APP_BACKEND_URL +  API_FETCH_MODEL_MEDIA_ROUTE + id + '/gallery',
          {
              params: {
                  show_photos: show_photos ? 1 : 0,
                  show_videos: show_videos ? 1 : 0
                },
              withCredentials: true,
              headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${sessionStorage.getItem("access_token")}`,
              }
          }
      );
      if (res.status === 200) {
        dispatch(setModelGallery(res.data.data))
      }
      dispatch(removeLoadings('media'));
  } catch (error) {
      console.log(error)
      dispatch(removeLoadings('media'));
  }
}



export const getModelPricelist = () => async (dispatch) => {
  try {

    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_MODEL_PRICELIST_ROUTE,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
        credentials: 'include'
      },
    )

    if (res.data) {
      dispatch(setPricing(res.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export const updateModelPriceList = (type, data) => async (dispatch) => {
  try {

    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_MODEL_PRICELIST_ROUTE,
      {[type]: data},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
        credentials: 'include'
      },
    )

    if (res.data) {
      dispatch(setPricing(res.data.data));
    }
  } catch (error) {
    console.log(error)
  }
}

export const deleteMedia = (id, type = null) => async (dispatch) => {
  try {
    console.log(type)
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + (type === 'video' ? API_MODEL_VIDEO_DELETE_ROUTE : API_MODEL_MEDIA_DELETE_ROUTE) + id,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
        credentials: 'include',
      }
    );
    //response.data
    const data = await response.json();
    dispatch(allModels(data.models));
    getModels()
    dispatch(setMessage(data.message, data.type))
  } catch (error) {
    setMessage(error, 'error');
  }
};


export const getModel = (username) => async (dispatch) => {
  dispatch(setLoadings('model'));
  try {
    const request = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_MODEL_ROUTE + username, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': `Bearer ` + localStorage.getItem("access_token")
      },
      credentials: 'include'
    })
    const response = await request.json();

    if (response.data) {
      dispatch(oneModel(response.data))
      dispatch(removeLoadings('model'));
    }
  } catch (error) {
    console.log(error)
    dispatch(removeLoadings('model'));
  }
}

export const getModels = () => async (dispatch) => {

  dispatch(setLoadings('models'));
  try {
    const request = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_MODELS_ROUTE, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': `Bearer ` + localStorage.getItem("access_token")
      },
      credentials: 'include'
    })
    const response = await request.json()
    if (response.data) {
      dispatch(removeLoadings('models'));
      dispatch(allModels(response.data))
    } else {
      dispatch(removeLoadings('models'));
      useNavigate(-1);
    }

  } catch (error) {
    console.log(error)
    dispatch(removeLoadings('models'));
  }
}

export const getOnline = () => async (dispatch) => {
  dispatch(setLoadings('onlines'));
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_MODELS_ROUTE_ACTIVE,
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          'Authorization': `Bearer ` + localStorage.getItem("access_token")
        },
      },
    )
    if (res.data) {
      dispatch(allOnline(res.data.data))
    }
  } catch (error) {
    console.log(error)
  }
  dispatch(removeLoadings('onlines'));
}

export const getModelEarnings = (month, year) => async (dispatch) => {
  let response = ''
  try {
    if ((month || year) === 0) {
      response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_MODEL_EARNINGS_ROUTE,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            'Authorization': `Bearer ` + localStorage.getItem("access_token")
          },
          //credentials: 'include',
        }
      );
    }
    else {
      response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_MODEL_EARNINGS_ROUTE + `?month=${month}&year=${year}`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            'Authorization': `Bearer ` + localStorage.getItem("access_token")
          },
          //credentials: 'include',
        }
      );
    }
    //response.data
    const data = await response.json();
    if (response.status >= 200 && response.status <= 299) {
      dispatch(oneModelEarnings(data.data));
    } else {
      //dispatch(userLogout());
      //localStorage.removeItem("token");
    }
  } catch (error) {
    console.log(error);
  }
}


