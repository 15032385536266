// theme.js
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { dark } from './dark';

const themes = {
    'light': dark,
    'dark': dark
}
export const createTheme = (name) => {
    return createMuiTheme(themes[name]);
}