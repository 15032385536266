import {EDIT_WATCHING, SET_WATCHING } from "../types";

export const WatchModelMediaRedcuer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_WATCHING: {
      let { data } = payload;
      return data;
    }
    case EDIT_WATCHING: {
      let { data } = payload;
      let newState = Object.assign({},state);
      newState.isPurchased = 1;
      newState.fullMovie = data.fullMovie
      return newState;
    }

    default: {
      return state;
    }
  }
};
