import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Persistor, store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/styles/App.scss";
import Loader from "./components/Loader/Loader";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GlobalDebug } from "./utils/remove-consoles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));

const onBeforeLift = () => ({});
process.env.NODE_ENV === "production" && Sentry.init({
  dsn: "https://8d114297089749b19ca5c4a6c6487f3c@o4504242204704768.ingest.sentry.io/4504242206474240",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
process.env.NODE_ENV === "production" && GlobalDebug(false, true);

console.log("I am just another dummy console log,");

root.render(
  <Provider store={store}>
    <PersistGate
      persistor={Persistor}
      onBeforeLift={onBeforeLift}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
