import { CAMERAS } from '../types'

const initialState = [];
export const camerasReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case CAMERAS: {
        const { data } = payload
        state = initialState;
        return [...state, ...data]
    }
    default: {
      return state
    }
  }
}
