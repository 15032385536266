import { BINOM, BINOM_REMOVE } from '../types'

export const binomReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case BINOM: {
      const { data } = payload
      return data
    }
    case BINOM_REMOVE: {
        return {}
      }
    default: {
      return state
    }
  }
}
