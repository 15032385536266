import { OFFLINE, ONLINE } from '../types'
const initialState = false;
export const connectionReducer = (state = initialState, action) =>{
  const {type} = action
  
  switch (type) {
    case ONLINE: {
      return true
    }
    case OFFLINE: {
        return false
      }
    default: {
      return state
    }
  }
}
