/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Avatar, Stack } from '@mui/material';

export default function AvatarPurchaseCard({avatar}) {

    return (
       
            <Stack className='buyAvatarElem' spacing={2} style={{ margin: '5px auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '130px', cursor: 'pointer' }}>
                    <Avatar
                        alt="Remy Sharp"
                        src={avatar ? avatar : "/static/images/avatar/1.jpg"}
                        sx={{ width: 75, height: 75, textAlign: 'center' }}
                    />
                    <Typography id="modal-modal-title" variant="h6" component="span" style={{ margin: '0', textAlign: 'center' }}>
                        Kissed Skull
                    </Typography>
                    <Typography id="modal-modal-title" variant="subtitle2" component="span" style={{ margin: '0', color: "#77F73B", textAlign: 'center' }}>
                        $79.99
                    </Typography>
            
            </Stack>

    );
}