import { PRIVATE_MESSAGES, ADD_MESSAGE_TO_PRIVATE_MESSAGES, PRIVATE_MESSAGES_REMOVE } from '../types'

const initialState = [];
export const messagePrivateReducer = (state = initialState, action) =>{
  const {type, payload} = action
  
  switch (type) {
    case PRIVATE_MESSAGES: {
      const { data } = payload
      return data
    }
    case ADD_MESSAGE_TO_PRIVATE_MESSAGES: {
      const { data } = payload

      if(state.some(item => data.id === item.id))
        return state
      
      let lastMessage = state[state.length - 1] ? state[state.length - 1] : null;

      if (lastMessage) {
        data.isPrevious = (lastMessage && data.data && data.data.userId === lastMessage.data.userId ? true : false);
          if (data.isPrevious) {
              data.previousSameType = (lastMessage && data.type === lastMessage.type ? true : false);
              if (data.previousSameType) {
                  data.previousId = lastMessage.previousId ? lastMessage.previousId : lastMessage.id;
              } else {
                  data.previousId = null;
              }
          }
      }
      return [...state, data]
    }
    case PRIVATE_MESSAGES_REMOVE: {
      return initialState;
    }

    default: {
      return state
    }
  }
}
