import { POSTS } from '../types'

export const postsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {

    case POSTS: {
      let { data } = payload
      return data
    }
    default: {
      return state
    }
  }
}
