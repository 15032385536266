import { palette } from './palette';

export const dark = {
  palette: {
    ...palette,
    type: 'dark'
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
    MuiAppBar: {
      color: 'transparent',
    },
  },
  components: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'black'
      }
    },
    MuiTab: {
      selected: {
        backgroundColor: '#242424',
        color: 'white'
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: palette.primary.main
        }
      }
    }
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    button: {
      fontWeight: 1000,
    },
  },
  spacing: 8,
};