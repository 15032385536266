import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { gsap } from "gsap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function CloseButton({ onClick, sx, iconStyle }) {
  const iconRef = React.useRef();
  const tl = React.useRef();
  const { t } = useTranslation();
  function hover() {
    if (!isMobile) tl.current.play();
  }

  function leave() {
    if (!isMobile) tl.current.reverse(-1);
  }

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true, repeat: -1 });
      tl.current.to(iconRef.current, { scale: 1.15, duration: 0.3 });

      tl.current.to(iconRef.current, { scale: 0.95, duration: 0.3 });
    });
    return () => ctx.revert();
  }, []);

  return (
    <IconButton
      onMouseOver={hover}
      onMouseLeave={leave}
      title={t("Close")}
      className="BackButton"
      onClick={onClick}
      sx={sx}
    >
      <CloseIcon ref={iconRef} sx={{ color: "#fff" }} style={iconStyle}/>
    </IconButton>
  );
}
