import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Fade, List, ListItem, Typography } from "@mui/material";
import { hideModal } from "../../redux/reducers/rootReducer";
import CloseButton from "../../UI/CloseButton";
import { isMobile } from "react-device-detect";
import Pool from "../ShowPools/Pool";
import CreateShow from "../ShowPools/CreateShow";

export default function ModelShowsModal({ modal }) {
  const dispatch = useDispatch();
  const unfilteredPools = useSelector((state) => state.pools);
  const user = useSelector((state) => state.session.user);
  const [pools, setPools] = React.useState([]);

  const stylePass = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 480,
    minWidth: 320,
    bgcolor: "#171717",
    height: isMobile ? "80vh" : "fit-content",
    minHeight: isMobile ? "40vh" : "17vh",
    maxHeight: isMobile ? "100vh" : "80vh",
    width: isMobile ? "95%" : "100%",
    pt: isMobile ? 4 : 4,
    px: 1,
    pb: isMobile ? 2 : 4,
    color: "#FFFFFF",
    borderRadius: "10px",
  };

  const handleClose = () => {
    dispatch(hideModal("modelShows"));
  };

  React.useEffect(() => {
    if (user?.id && unfilteredPools?.length > 0) {
      let newState = [];
      unfilteredPools
        .sort(function (a, b) {
          if (a.id < b.id) return 1;
          if (a.id > b.id) return -1;
          return 0;
        })
        .map((pool) => {
          let found = false;
          pool.performers.length > 0 &&
            pool.performers.map((performer) => {
              if (performer.inHouseName === user.inHouseName && !found) {
                found = true;
                newState.push(pool);
              }
            });
        });
      setPools(newState);
    }
  }, [unfilteredPools, user]);

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={modal && modal.name === "modelShows"}
      onClose={handleClose}
    >
      <Fade in>
        <Box sx={stylePass}>
          <CloseButton onClick={handleClose} />
          <CreateShow />
          <Box sx={{ width: "100%" }}>
            <Fade in delay={200} timeout={400}>
              <Box
                sx={{ width: "100%", typography: "body1", color: "#FFFFFF" }}
              >
                {pools?.length > 0 ? (
                  <List
                    sx={{
                      width: "100%",
                      height: window.innerHeight / 1.5 + "px",
                      overflowY: "scroll",
                    }}
                  >
                    {pools.map((pool, index) => {
                      return (
                        <ListItem sx={{ width: "100%" }} key={"pol" + index}>
                          <Pool pool={pool} poolType="myModel" />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Box className="flex center" sx={{ py: 3 }}>
                    <Typography sx={{ color: "#ffffff95" }}>
                      You don't have any show pools at the moment.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Fade>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
