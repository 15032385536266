import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
function StatusFooter({ status }) {
  const { t } = useTranslation();
  return (
    <footer className={"footer_status couponFooter"}>
      <Grid xs={12} item className={"couponGrid"}>
        <Grid
          className=""
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography
              color={"black"}
              style={{ font: "normal normal medium 16px/19px Effra" }}
            >
              {t("Your Account status:")}
              &nbsp;
              {status && status}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default StatusFooter;
