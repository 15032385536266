import { FETCH_SESSION_REQUESTS, REMOVE_SESSION_REQUEST } from '../types'

const initialState = [];
export const sessionReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case FETCH_SESSION_REQUESTS: {
      const { data } = payload
      state = initialState;
      return data
    }
    case REMOVE_SESSION_REQUEST: {
      const { data } = payload
      state = initialState;
      return data
    }
    default: {
      return state
    }
  }
}
