// App.js
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Maintance from "./components/Maintance/Maintance";
import Router from "./router/Router";
import { createTheme } from "./themes/theme";
import useSettings from "./themes/useSettings";
import SnackbarUI from "./UI/SnackbarUI";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import io from "socket.io-client";
// import {
//   setEditProfile,
//   setMessage,
//   showModal,
// } from "./redux/reducers/rootReducer";
// import {
//   API_RESTORE_ROUTE,
//   CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN,
//   CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD,
//   CORE_API_LOGIN_ROUTE,
// } from "./utils/consts";
import { Box } from "@mui/material";
import { PlayerControls } from "./context/PlayerControls";
import Modals from "./components/Modals/Modals";
import StatusFooter from "./components/Housemates/StatusFooter";

export const ScreenContext = React.createContext();
export const SocketContext = React.createContext();

let connected = false;

const App = () => {
  const { settings } = useSettings();
  // const location = useLocation();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const theme = createTheme(settings.theme);
  const maintance = useSelector((state) => state.maintance);
  const status = localStorage.getItem("account_status");
  const hasWindow = typeof window !== "undefined";

  const [width, setWidth] = React.useState(null);
  const [socket, setSocket] = React.useState(null);
  // const user_token = useSelector((state) => state.session.kewb_token);

  // React.useEffect(() => {
  //   if (socket) {
  //     socket.removeAllListeners(["connect", "environmentUpdated"]);
  //   }
  //   // Removing trailing slash from the URL.
  //   if (
  //     location.pathname[location.pathname.length - 1] === "/" &&
  //     location.pathname.length > 1
  //   )
  //     navigate(location.pathname.slice(0, -1));
  //   if (location.pathname.includes("www."))
  //     navigate(location.pathname.replace("www.", ""));
  //   if (window.location.host.startsWith("www")) {
  //     var newHost = window.location.host.replace("www.", "");
  //     var newUrl =
  //       window.location.protocol + "//" + newHost + window.location.pathname;
  //     //console.log('newUrl', newUrl);
  //     window.location = newUrl;
  //   }
  //   dispatch(setEditProfile(false));
  //   const queryParams = new URLSearchParams(window.location.search);
  //   // if (
  //   //   queryParams.get("clickid") &&
  //   //   queryParams.get("uclick") &&
  //   //   queryParams.get("uclickhash")
  //   // ) {
  //   //   let item = {
  //   //     binom: binom_send,
  //   //   };
  //   //   dispatch(UpdateBinom(item));
  //   // }

  //   if (
  //     queryParams.get("user") &&
  //     queryParams.get("token") &&
  //     location.pathname.includes("reset-password")
  //   ) {
  //     console.log('here')
  //     let token = queryParams.get("token");
  //     let user = queryParams.get("user");
  //     fetch(
  //       process.env.REACT_APP_BACKEND_URL +
  //         API_RESTORE_ROUTE +
  //         "?" +
  //         new URLSearchParams({
  //           user: user,
  //           token: token,
  //         }),
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.success) {
  //           dispatch(
  //             showModal("reset", {
  //               token: token,
  //               user: user,
  //             })
  //           );
  //         } else {
  //           dispatch(
  //             setMessage(
  //               "The password restoration link has expired. Please request a new one.",
  //               "error"
  //             )
  //           );
  //         }
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // const user_age = localStorage.getItem("user_age_check");

  // React.useEffect(() => {
  //   if (!connected) {
  //     if (user_age === "true") {
  //       console.log("Connecting to socket...");
  //       if (!user_token) {
  //         fetch(process.env.REACT_APP_CORE_API_URL + CORE_API_LOGIN_ROUTE, {
  //           method: "POST", // or 'PUT'
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             login: CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN,
  //             password: CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD,
  //           }),
  //         })
  //           .then((response) => response.json())
  //           .then((data) => {
  //             let token = data.data.token;
  //             localStorage.setItem("core_api_token", data.data.token);
  //             let socketio = io.connect(process.env.REACT_APP_CORE_API_URL, {
  //               query: { token },
  //               transports: ["polling", "websocket"],
  //               reconnection: true,
  //               reconnectionDelay: 3500,
  //             });
  //             socketio.token = token;
  //             setSocket(socketio);
  //             connected = true;
  //           });
  //       } else {
  //         let token = user_token;
  //         localStorage.setItem("core_api_token", token);
  //         let socketio = io.connect(process.env.REACT_APP_CORE_API_URL, {
  //           query: { token },
  //           transports: ["polling", "websocket"],
  //           reconnection: true,
  //           reconnectionDelay: 3500,
  //         });
  //         socketio.token = token;
  //         setSocket(socketio);
  //         connected = true;
  //       }

  //       return () => {
  //         if (socket && socket.readyState === 1) {
  //           socket.close();
  //           setSocket(null);
  //         }
  //         if (socket) {
  //           socket._callbacks = [];
  //           socket.close();
  //           setSocket(null);
  //         }
  //         connected = false;
  //       };
  //     }
  //   } else {
  //     socket.socket.connect();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user_token, user_age]);

  React.useEffect(() => {
    const getDimensions = () => {
      setWidth(hasWindow ? window.innerWidth : null);
    };
    getDimensions();
    window.addEventListener("resize", getDimensions);

    return () => {
      window.removeEventListener("resize", getDimensions);
    };
    //   window.addEventListener(
    //     "touchmove",
    //     function(event) {
    //         if (event.scale !== 1) {
    //             event.preventDefault();
    //         }
    //     },
    //     { passive: false }
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PlayerControls>
        <ScreenContext.Provider value={width}>
          {status && status == "away" && <StatusFooter status={"away"} />}
          <Maintance />
          <Modals />
          <Box sx={{ pt: maintance && "95px" }}>
            <SocketContext.Provider value={socket}>
              <Router />
            </SocketContext.Provider>
          </Box>
          <SnackbarUI />
        </ScreenContext.Provider>
      </PlayerControls>
    </ThemeProvider>
  );
};

export default App;
