import { BALANCE, DECREMENT_CREDITS, INCREMENT_CREDITS } from '../types'

export const balanceReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case BALANCE: {
        const { data } = payload;
        return data
    }
    case DECREMENT_CREDITS: {
      const { data } = payload
      const newState = Object.assign({},state);
      newState.credits.total -= data;
      return newState
    }
    case INCREMENT_CREDITS: {
      const { data } = payload
      const newState = Object.assign({},state);
      newState.credits.total += data;
      return newState
    }
    default: {
      return state
    }
  }
}
