import { CHAT } from '../types'

export const chatReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case CHAT: {
      const { data } = payload
      return data
    }
    default: {
      return state
    }
  }
}
