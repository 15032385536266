import React from "react";
import { Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import history from "../redux/history";
import { connect } from "react-redux";
import { lazy } from "react";
const Magic = lazy(() => import("../pages/Magic/Magic"));
const Page404 = lazy(() => import("../pages/Page404/Page404"));
const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

function Router({ session }) {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname === "/login" && session.user) navigate("/");

    if (!session.user) {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("core_api_token");
      localStorage.removeItem("access_token");
      window.sessionStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense
      fallback={
        <div className={`loader-wrapper`}>
          <div className="loader"></div>
        </div>
      }
    >
      <Routes Routes history={history}>
        <Route path="/" element={<Magic session={session} />} />
        <Route path="*" element={<Page404 session={session} />} status={404} />
      </Routes>
    </Suspense>
  );
}

export default connect(mapStateToProps)(Router);
