import {
  Autocomplete,
  Box,
  Button,
  Input,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./CreateShow.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { createPool } from "../../redux/actions/poolShowActions";
import { gsap } from "gsap";
import { isMobile } from "react-device-detect";

export default function CreateShow() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const unfilteredHousemates = useSelector((state) => state.onlines);
  const [housemates, setHousemates] = React.useState(null);

  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  const [housem, setHousem] = useState([]);
  const [target, setTarget] = useState("");
  const [hh, setHH] = useState("");
  const [mm, setMM] = useState("");
  const [ids, setIds] = useState([]);

  const iconRef = React.useRef();
  const tl = React.useRef();

  function hover() {
    if (!isMobile) tl.current.play();
  }

  function leave() {
    if (!isMobile) tl.current.reversed(true);
  }

  React.useEffect(() => {
    if (unfilteredHousemates?.length > 0) {
      setHousemates(
        unfilteredHousemates.sort(function (a, b) {
          if (a.inHouseName.toLowerCase() < b.inHouseName.toLowerCase())
            return -1;
          if (a.inHouseName.toLowerCase() > b.inHouseName.toLowerCase())
            return 1;
          return 0;
        })
      );
    }
  }, [unfilteredHousemates]);

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true });
      tl.current.to(iconRef.current, {
        rotate: 405,
      });
    });
    return () => ctx.revert();
  }, []);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreate = (event) => {
    dispatch(
      createPool({
        description: desc,
        credits_target: target,
        hours: hh,
        minutes: mm,
        participants: ids,
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDesc("");
    setHousem([]);
    setIds([]);
    setTarget("");
    setHH("");
    setMM("");
  };

  const handleAutocompleteChange = (event) => {
    //event.preventDefault();
    setHousem(event.target.value);
    let array = [];
    for (let index = 0; index <= event.target.value.length - 1; index++) {
      const girl = event.target.value[index];
      array.push(girl.id);
    }
    setIds(array);
  };
  function handleKeyDown(e) {
    e.stopPropagation();
  }
  return (
    <>
      <Button
        onMouseOver={hover}
        onMouseLeave={leave}
        className="btn btn-yellow"
        sx={{
          display: "flex",
          margin: "20px auto",
          alignItems: "center",
          gap: "5px",
          px: 5,
          py: 1.5,
        }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon ref={iconRef} />
        <span>Create show</span>
      </Button>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-create-show"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box
          className="flex column"
          sx={{
            background: "black",
            padding: "7px",
            gap: "8px",
            maxWidth: "405px",
          }}
        >
          <Input
            type={"text"}
            onKeyDown={handleKeyDown}
            onChange={(e) => setDesc(e.target.value)}
            value={desc}
            placeholder="Performance description"
            autoFocus
            disableUnderline
            className={styles.input}
          />
          <Autocomplete
            value={housem}
            onKeyDown={handleKeyDown}
            underlineStyle={{ display: "none" }}
            name="participants"
            className={styles.input}
            multiple
            sx={{
              "& .MuiAutocomplete-clearIndicator": {
                color: "#fff",
              },
            }}
            id="housemates-input-auto"
            onChange={(event, value) => {
              handleAutocompleteChange({
                target: {
                  value: value,
                },
              });
            }}
            isOptionEqualToValue={(option, value) =>
              option.inHouseName === value
            }
            options={
              housemates?.length > 0
                ? housemates.map((housemate) => housemate)
                : []
            }
            getOptionLabel={(option) => option.inHouseName}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-root:before": {
                    display: "none",
                  },
                  "& .MuiInputBase-root:after": {
                    display: "none",
                  },
                  fieldset: { border: "0" },
                }}
                variant="standard"
                placeholder="Housemates"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <Box className="flex items-center" sx={{ marginLeft: "5px" }}>
            <Typography sx={{ width: "95px" }}>Target</Typography>
            <Input
              type={"number"}
              onChange={(e) => setTarget(e.target.value)}
              value={target}
              className={styles.inputSm}
              disableUnderline
              placeholder="Credits"
              sx={{ minWidth: "74px" }}
            />
          </Box>
          <Box className="flex items-center" sx={{ marginLeft: "5px" }}>
            <Typography sx={{ width: "95px" }}>Countdown</Typography>
            <Input
              type={"number"}
              onChange={(e) =>
                setHH(e.target.value <= 71 ? e.target.value : hh)
              }
              value={hh}
              className={styles.inputSm}
              disableUnderline
              placeholder="HH"
              sx={{ width: "43px", marginRight: "8px" }}
            />
            <Input
              type={"number"}
              onChange={(e) =>
                setMM(e.target.value <= 59 ? e.target.value : mm)
              }
              value={mm}
              className={styles.inputSm}
              disableUnderline
              placeholder="MM"
              sx={{ width: "43px" }}
            />
          </Box>
          <Box
            className="flex"
            sx={{ justifyContent: "space-between", marginTop: "15px" }}
          >
            <Button
              onClick={handleCreate}
              className="btn-bordered"
              style={{ color: "#78F73B", padding: "5px 15px" }}
            >
              create
            </Button>
            <Button
              onClick={handleClose}
              className="btn-bordered"
              style={{ color: "#F73B3B", padding: "5px 15px" }}
            >
              cancel
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
}
