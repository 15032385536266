import { Button, Menu } from '@mui/material';
import React, { useState } from 'react'
import {} from '../../redux/actions/poolShowActions';
import EditIcon from '@mui/icons-material/Edit';
import unixToDate from '../../utils/date';
import ActivePools from './ActivePools';

export default function EditShow({pool, liveshow, poolType}) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [hh, setHH] = useState('')
    const [mm, setMM] = useState('')

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setHH(''); setMM('');
    };

    function convertNumToTime(number) {
        // Check sign of given number
        var sign = (number >= 0) ? 1 : -1;
    
        // Set positive value of number of sign negative
        number = number * sign;
    
        // Separate the int from the decimal part
        var hour = Math.floor(number);
        var decpart = number - hour;
    
        var min = 1 / 60;
        // Round to nearest minute
        decpart = min * Math.round(decpart / min);
    
        var minute = Math.floor(decpart * 60) + '';
    
        // Add padding if need
        if (minute.length < 2) {
        minute = '0' + minute; 
        }
    
        // Add Sign in final result
        sign = sign === 1 ? '' : '-';
    
        // Concate hours and minutes
        let time = sign + hour + ':' + minute;
    
        return time;
    }

    React.useEffect(()=> {

        if(!pool)
            return;
        
        var date1 = new Date();
        var date2 = unixToDate(pool.valid_to);
        var diff = date2.valueOf() - date1.valueOf();
        var diffInHours = diff/1000/60/60; // Convert milliseconds to hours

        if(diffInHours < 0){
            diffInHours = 0;
        }
        const hhmm = convertNumToTime(diffInHours);
        let hhMMarr = hhmm.split(':');
        setHH(hhMMarr[0]);
        setMM(hhMMarr[1]);
    }, [pool, anchorEl]);

    return (
        <>
            <Button className="btn btn-yellow" sx={{ display: 'flex', marginLeft: "auto", alignItems: 'center', gap: '5px', height: '45px' }} onClick={handleOpen}><EditIcon />Edit</Button>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-create-show"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <ActivePools pool={pool} liveshow={liveshow} poolType="myModel"/>
            </Menu>
        </>
    )
}
