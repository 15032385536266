import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { Container } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Input } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import moment from "moment";
import { AccomplishPool, ExtendPool } from "../../../redux/actions/poolShowActions";

export default function ActivePools({ pool, models, liveshow }) {

    const dispatch = useDispatch();

    const theme = createTheme({
        palette: {
            success: {
                // Purple and green play nicely together.
                main: '#78F73B',
            },
            error: {
                // This is green.A700 as hex.
                main: '#F73B3B',
            },
        },
    });

    const [progress, setProgress] = React.useState((pool && pool.credits_current === 0 ? 0 : (pool.credits_current / pool.credits_target) * 100));


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 8,
        borderRadius: 100,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#292929',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 100,
            backgroundColor: "#78F73B",
        },
    }));
    const [time, setTime] = React.useState(Date.now());

    React.useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    function getTimeStamp(time, dateEnd) {
        var date = new Date(dateEnd * 1000);
        const endDate = moment(date);
        const startDate = moment(time);
        const diff = endDate.diff(startDate);
        var diffDuration = moment.duration(diff)
        const days = diffDuration.days();
        const hours = diffDuration.hours() + days * 24; // get hours with getHours method
        const minutes = diffDuration.minutes(); // get minutes with getMinutes method
        const seconds = diffDuration.seconds(); // get seconds with getSeconds method
        const timeString = `${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`;
        return timeString; // finally, join to a time string
    }
    function pad(num) {
        return ("0" + num).slice(-2);
    }

    const [extend, setExtend] = React.useState(false);

    function Change() {
        setExtend(true)
    }


    const [addFormData, setAddFormData] = React.useState({
        hours: "",
        minutes: "",

    });
    const handleAddForm = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };

    function handleSubmit(e) {
        if (extend === true) {
            dispatch(ExtendPool(addFormData, pool.id, (liveshow && liveshow.liveshow.id) ? 'liveshow' : 'all', (liveshow && liveshow.liveshow.id)));
            setExtend(false)
            addFormData.hours = ''
            addFormData.minutes = ''

        }

    };
    function handleAccomplish(e) {
        dispatch(AccomplishPool(pool.id, (liveshow && liveshow.liveshow.id) ? 'liveshow' : 'all', (liveshow && liveshow.liveshow.id)));
        setExtend(false)
        addFormData.hours = ''
        addFormData.minutes = ''
    };


    return (
        <>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: "550px",
                    margin: "0",
                    height: "100%",
                    minWidth: '300px'
                    /* minHeight: "500px", */
                }}
            >
                <Container
                    maxWidth="xl"
                    style={{
                        color: "#ffffff",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#000000",
                        borderRadius: "5px"
                    }}
                    sx={{ my: 3 }}
                    className="PoolContainer"
                >
                    <Grid spacing={1} className="Pool_wrap">
                        <Grid item xs={12}>
                            <Box component="div" className="Pool_main">
                                <Stack
                                    direction="row"
                                    className="Pools_row"
                                >
                                    <Input
                                        value={pool && pool.description}
                                        name="password"
                                        disabled
                                        className="Pools_input_text"
                                        disableUnderline="true"
                                        inputProps={{ maxLength: 70 }}
                                    ></Input>
                                </Stack>
                                <Stack
                                    className="Pools_row"
                                >
                                    <Autocomplete
                                        className="PoolLazyInput disable"
                                        multiple
                                        disabled
                                        disableUnderline="true"
                                        id="combo-box-demo"
                                        options={models ? models : []}
                                        defaultValue={pool && pool.performers}
                                        /* value={liveshow.performers ? liveshow.performers : []} */
                                        key={pool && pool.performers}
                                        isOptionEqualToValue={(option, value) =>
                                            option.iso === value.iso
                                        }
                                        getOptionLabel={(option) => option.inHouseName || ''}
                                        renderInput={(params) => (
                                            <TextField {...params} disableUnderline="true" variant="standard" />
                                        )}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    className="Pools_row"
                                >
                                    <Grid style={{ width: '70%' }}>
                                        <BorderLinearProgress variant="determinate" value={progress} />
                                    </Grid>
                                    <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end', marginLeft: "20px" }}>
                                        <Typography width={'150px'} color={progress === 100 ? '#77F73B' : 'white'} style={{ display: "flex", justifyContent: 'flex-end' }}>
                                            {pool && pool.credits_current} of {pool && pool.credits_target} Credits
                                        </Typography>
                                    </Grid>

                                </Stack>
                                <Stack
                                    direction="row"
                                    className="Pools_row"
                                >
                                    <Typography width={'70px'}>
                                        Time left
                                    </Typography>
                                    <Typography width={'100px'}
                                        className="time"
                                        marginLeft={'5px'}>
                                        {pool && pool.valid_to &&
                                            getTimeStamp(time, pool.valid_to)}
                                    </Typography>

                                    {pool && pool.credits_current >= pool.credits_target ?
                                        <><ThemeProvider theme={theme}>
                                            <Button
                                                size="medium"
                                                variant="outlined"
                                                /* color="success" */
                                                style={{ paddingLeft: "15px", marginLeft: '10px', maxWidth: "130px" }}
                                                color={"success"}
                                                onClick={() => handleAccomplish()}
                                            >
                                                {"Accomplish"}
                                            </Button>
                                        </ThemeProvider>

                                        </>
                                        :
                                        <><ThemeProvider theme={theme}>
                                            <Button
                                                size="medium"
                                                variant="outlined"
                                                /* color="success" */
                                                style={{ paddingLeft: "15px", marginLeft: '10px', maxWidth: "90px" }}
                                                color={"success"}
                                                onClick={() => { Change(); handleSubmit(); }}
                                            >
                                                {extend === true ? "Submit" : "Extend"}
                                            </Button>
                                            {extend === true ?
                                                <><Input
                                                    value={addFormData.hours}
                                                    name="hours"
                                                    onChange={handleAddForm}
                                                    placeholder="HH"
                                                    className="Pools_input_time"
                                                    disableUnderline="true"
                                                    inputProps={{ maxLength: 70, color: 'white' }}
                                                ></Input><Input
                                                    value={addFormData.minutes}
                                                    name="minutes"
                                                    onChange={handleAddForm}
                                                    placeholder="MM"
                                                    className="Pools_input_time"
                                                    disableUnderline="true"
                                                    inputProps={{ maxLength: 70 }}
                                                ></Input></> : ''}
                                        </ThemeProvider>
                                        </>
                                    }
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}