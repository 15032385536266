import { ADD_MEDIA, MEDIA } from '../types'
const initialState = [];
export const mediasReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {
    case MEDIA: {
      const { data } = payload
      return data;
    }

    case ADD_MEDIA: {
      const { data } = payload
      let newState = Object.assign([],state);
      newState.media = newState.media.concat(data.media);
      newState.pagination = data.pagination;
      newState.filters = data.filters;
      return newState;
    }

    default: {
      return state;
    }
  }
}
