import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import StringHelper from "../../../utils/StringHelper";
import UserMenu from "../../UserMenu/UserMenu";
import { Link } from 'react-router-dom';
export default function PerformerBox({ model }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (e) => {
    e.preventDefault();
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Box
        key={"po" + model.id}
        sx={{
          display: "flex",
          width: "fit-content",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Link
          rel="canonical"
          onClick={(e) => handleMenu(e)}
          to={`/housemate/${StringHelper.replaceSpacesWithDashes(
            model && model.inHouseName
          ).toLowerCase()}`}

        >
          <Avatar
            loading="lazy"
            className={"onlineCard " + (model && model.onlineStatus)}
            alt={model && model.inHouseName + " profile picture"}
            src={
              model &&
              model.avatar &&
              model.avatar.imageMedium &&
              model.avatar.imageMedium
            }
          />
        </Link>
        <Typography sx={{ fontSize: "10px", marginTop: "4px" }}>
          {model && model.inHouseName}
        </Typography>
      </Box>

      <UserMenu
        setOpen={setOpen}
        open={open}
        anchorEl={anchorEl}
        model={model}
      />
    </>
  );
}
