/* eslint-disable array-callback-return */
import { REMOVE_PINNED_MESSAGE, REMOVE_PINNED_MESSAGES, SET_PINNED_MESSAGE } from '../types'

const initialState = [];
export const pinnedMessagesReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_PINNED_MESSAGE: {
      const { data } = payload

      if (state.some(item => data.message.id === item.message.id))
        return state

      let newState = Object.assign([], state);

      newState.map((item) => {
        if (item.chatId === data.chatId) {
          item.message = data.message;
          return newState;
        }
      });

      return [...state, data]
    }
    case REMOVE_PINNED_MESSAGE: {
      const { data } = payload
      return state.filter(item => data.message.id !== item.message.id);
    }
    case REMOVE_PINNED_MESSAGES: {
      return initialState
    }
    default: {
      return state
    }
  }
}
