import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import "video-react/dist/video-react.css";
import { deleteMedia, getModelMedia } from "../../redux/actions/modelAction";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import styled from "./StreamScreen.module.css";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state) => {
  return {
    model_media: state.media,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getModelMedia: (id) => dispatch(getModelMedia(id)),
  };
};

const UploaderVideo = ({ model_media, getModelMedia, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  React.useEffect(() => {
    getModelMedia(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let newFileList = [];
    if (model_media && model_media.videos && model_media.videos.length === 0) {
      setFileList([]);
    }
    model_media &&
      model_media.videos &&
      model_media.videos.forEach((video) => {
        newFileList.push({
          uid: video.id,
          status: "done",
          url:
            video.poster_image &&
            video.poster_image.mediaMeta &&
            video.poster_image.mediaMeta.imageLarge,
          response: '{"status": "success"}', // response from server
          linkProps: '{"download": "video"}',
        });
        setFileList(newFileList);
      });
  }, [model_media]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        "/api/v1/models/media/video/" +
        file.uid,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res.data) {
      setPreviewImage(res.data.data.player);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handleValidate = (info) => {
    const isPNG = info.type.includes("video");
    if (!isPNG) {
      alert(`file must be type of video`);
    }
    return isPNG || Upload.LIST_IGNORE;
  };

  const handleRemove = (info) => {
    // eslint-disable-next-line no-unused-expressions
    handleChange;
    dispatch(deleteMedia(info.uid, "video"));
  };
  const uploadButton = (
    <div style={{ backgroundColor: "black !important" }}>
      <PlusOutlined style={{ color: "white" }} />
      <div
        style={{
          marginTop: 8,
          color: "white",
        }}
      >
        {t("Upload")}
      </div>
    </div>
  );

  const props = {
    action:
      process.env.REACT_APP_BACKEND_URL +
      `/api/v1/models/media/video/store/${id && id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    listType: "picture-card",
    name: "fullMovie",
  };

  return (
    <>
      <Upload
        {...props}
        fileList={fileList}
        data={{ mediaType: "video" }}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        beforeUpload={handleValidate}
        defaultFileList={setFileList}
        key={setFileList}
        style={{ backgroundColor: "black", color: "white" }}
      >
        {fileList.length >= 20 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <ReactHlsPlayer
          id="main_video"
          src={previewImage && previewImage}
          controls={true}
          className={styled.VideoScreen}
          style={{ maxWidth: "1350px", minWidth: "300px", width: "100%" }}
          muted={true}
          autoPlay
        />
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploaderVideo);
