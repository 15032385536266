import { MAINTANCE_REMOVE, MAINTANCE_SET } from '../types'

export const maintanceReducer = (state = false, action) =>{
  const {type} = action

  switch (type) {
    case MAINTANCE_SET: {
        return true;
    }
    case MAINTANCE_REMOVE: {
      return false;
    }
    default: {
      return state;
    }
  }
}
